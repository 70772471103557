import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { default as pdp } from '../../../pdp_agent.jpg';
import { Button, Stack } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../index.css';
var axios = require('axios');


export default function Step12(props) {
  // const [code, setCode] = useState(['', '', '', '']);
  const [nb1, setNb1] = useState('');
  const [nb2, setNb2] = useState('');
  const [nb3, setNb3] = useState('');
  const [nb4, setNb4] = useState('');
  const [focus, setFocus] = useState('nb1')
  const [dontSend, setDontSend] = useState(false);
  const [tel, setTel] = useState(props.num);
  const [redirect, setRedirect] = useState();

  const nb1Ref = React.useRef(null);
  const nb2Ref = React.useRef(null);
  const nb3Ref = React.useRef(null);
  const nb4Ref = React.useRef(null);

  const { agent } = props;

  useEffect(() => onFinish(), [nb1, nb2, nb3, nb4]);

  useEffect(
    () => {
      let timer1 = setTimeout(() => setDontSend(true), 5000);

      // this will clear Timeout
      // when component unmount like in willComponentUnmount
      // and show will not change to true
      return () => {
        clearTimeout(timer1);
      };
    },
    // useEffect will run only one time with empty []
    // if you pass a value to array,
    // like this - [data]
    // than clearTimeout will run every time
    // this value changes (useEffect re-run)
    []
  );

  const sendConfirmCode = () => {
    var axios = require('axios');
    const code = (nb1 + nb2 + nb3 + nb4).toString();
    const num = '+33' + props.num;
    var data = JSON.stringify({tel: num, action: 1, code: code, data: props.data});
    console.log(props.data);

    let urlProd = 'https://api.estimateur-immo.com/estimation/send';

    var config = {
      method: 'post',
      url: urlProd,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios(config)
      .then(function (response) {
        console.log("ici response DATA ")
        console.log(JSON.stringify(response.data));

        console.log("response.data._id ")
        console.log(response.data._id)

        props.redirect(response.data._id)
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const resendCodeConfirm = () => {
    const numero = "+33" + tel;
    var data = JSON.stringify({"tel": numero});

    // props.nextStep(14);


    let urlProd = 'https://api.estimateur-immo.com/contact/send-sms';



    var config = {
      method: 'post',
      url: urlProd,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
        // props.nextStep(14);
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  const startTimer = () => {
    let timer1 = setTimeout(() => setDontSend(true), 5000)
    return () => {
      clearTimeout(timer1);
    };
  }

  const resendSms = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();     
    } else {
      setNb1('');
      setNb2('');
      setNb3('');
      setNb4('');
      setDontSend(false);
      nb1Ref.current.focus();
      startTimer();
      resendCodeConfirm();
    }
  }

  const onChangeCode = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    switch (name) {
      case "nb1":
        setNb1(value);
        if(value != ''){
          nb2Ref.current.focus();
        }
        break;
      case "nb2":
        setNb2(value);
        if(value != ''){
          nb3Ref.current.focus();
        }
        break;
      case "nb3":
        setNb3(value);
        if(value != ''){
          nb4Ref.current.focus();
        }
        break;
      case "nb4":
        setNb4(value);
        break;
      default:
        break;
    }
  }
  
  const onFinish = () => {
    if (nb1.length > 0 && nb2.length > 0 && nb3.length > 0 && nb4.length > 0){
      console.log("MAKE REQUEST LENGTH");
      sendConfirmCode()
      // makeRequest();
    }
  }

  const displayDontSend = () => {
    return (
      <>
        <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='resen1'>
        <Card className='cardChat' style={{marginTop: '20px'}}>
            <Row>
                <Col xs={2} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody">
                      Vous n'avez pas reçu le SMS ?<br />
                      Veuillez vérifier votre numéro ci-dessous et cliquez sur le bouton "Renvoyer moi un SMS"
                    </Card.Body>
                </Col>
            </Row>
        </Card>
        </div> 

        <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='resen2'>
        <Form style={{marginLeft: '15px', marginRight: '15px', marginTop: '20px'}}>
        <Row style={{display: 'flex', justifyContent: 'center'}}>
          <Form.Control required style={{maxWidth: '500px' , boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} minLength={10} type="tel" placeholder="Téléphone" maxLength={10} value={tel} onChange={(e) => setTel(e.target.value)} />
        </Row>

        <Row style={{display: 'flex', justifyContent: 'center'}}>
            <Button
            type="submit"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            style={{marginTop: '20px', maxWidth: '450px', textTransform: 'none', paddingLeft: '18px', paddingRight: '18px', fontSize: '1.5rem', backgroundColor: '#00c695', borderColor: '#00c695', lineHeight: 1.13, borderRadius: '15px', padding: '15.5px 24px', boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', whiteSpace: 'nowrap'}}
            // style={{textTransform: 'none', paddingLeft: '15px', paddingRight: '15px', borderRadius: '20px', backgroundColor: '#00c695', borderColor: '#00c695', fontSize: '1.2rem', fontWeight: 700, boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', marginTop: '2.5vh', marginRight: '3vh', marginLeft: '3vh', width: '90%', padding: '11px 0', whiteSpace: 'nowrap'}}
            // onClick={makeRequest}
            onClick={resendSms}
            >
            Renvoyer moi un SMS
            </Button>
        </Row>
      </Form>
      </div>
      </>
    )
  }

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
        <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='resen3'>
        <Card className='cardChat'>
            <Row>
                <Col xs={2} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody">
                      Pour visualiser votre estimation, veuillez saisir le code envoyé par SMS.
                    </Card.Body>
                </Col>
            </Row>
        </Card>
      </div> 

      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='resen4'>
        <Form style={{marginLeft: '15px', marginRight: '15px', marginTop: '20px'}}>
        <Stack direction="row" spacing={2} justifyContent="center">
          <Form.Control name="nb1" ref={nb1Ref} autoFocus type="number" style={{borderRadius: '4px', boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', maxWidth: '120px', textAlign: 'center'}} min={0} max={9} maxLength={1} value={nb1} onChange={onChangeCode} />
          <Form.Control name="nb2" ref={nb2Ref} type="number" style={{borderRadius: '4px', boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', maxWidth: '120px', textAlign: 'center'}} min={0} max={9} maxLength={1} value={nb2} onChange={onChangeCode} />
          <Form.Control name="nb3" ref={nb3Ref} type="number" style={{borderRadius: '4px', boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', maxWidth: '120px', textAlign: 'center'}} min={0} max={9} maxLength={1} value={nb3} onChange={onChangeCode} />
          <Form.Control name="nb4" ref={nb4Ref} type="number" style={{borderRadius: '4px', boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', maxWidth: '120px', textAlign: 'center'}} min={0} max={9} maxLength={1} value={nb4} onChange={onChangeCode} />
        </Stack>
        {/* <Row>
          <Form.Control type="text" placeholder="Code de confirmation" maxLength={14} value={code} onChange={(e) => setCode(e.target.value)} />
        </Row> */}
        
      </Form>
      </div>
      {dontSend ? displayDontSend() : null}
    </Container>
  )
}