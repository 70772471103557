import React from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import { Typography, Button, Icon, TextField } from "@mui/material";
import { Row, Container, Card, Form  } from 'react-bootstrap'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete from '@mui/material/Autocomplete';
import { PropTypes } from "prop-types";
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Header from '../WelcomePage/Header/Header';
import GoogleMaps from '../WelcomePage/Input/Input'
import DangerousIcon from '@mui/icons-material/Dangerous';
import { Navigate } from 'react-router-dom';
 import '../WelcomePage/welcome.css'
 import '../StepperPage/index.css'

import logo from '../../contact-icon.svg'
import '../StepperPage/index.css';
var axios = require('axios');

const styles = {
  area : {
    background: "rgba(210,213,219,0.4)",
    // background:  "linear-gradient(to  bottom, white, rgba(210,213,219,0.9), #FFCE00)" "-webkit-linear-gradient(to left, #8f94fb, #4e54c8)",
    width: '100vw', 
    minHeight: "100vh",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: '20px'
},

input : {
  padding: '15px',
  height: "6vh",
  marginTop: "5vh", 
  fontSize: "19px",
  justifyContent: 'end',
  /* font-style: italic; */
  "@media (max-width: 900px)": {
    width: "80vw", 
  },
  width: "40vw", 
   border: 'none',
   outline: 'none', 
  borderRadius: '5rem'
},
  circles : {
    position: 'absolute',
    top: 0,

    left: 0,
    width: '100vw',
    height: "100vh",
    overflow: "hidden",
},
  buttonn: {
    borderRadius: '20px',
    backgroundColor: '#00c695',
    textDecoration: 'none', 
    borderColor: '#00c695',
    fontWeight: 700,
    boxShadow: '4 5 17px 5 rgb(0 0 0 / 46%)'
  },

};

class Mentions extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      agent: null,
      url: null,
      error: null,
      redirect: false
    };
  }

  componentDidMount(){
    console.log(this.props);
    const agent = this.props.location.pathname.split('/')[1];
    console.log(agent);
    var data = JSON.stringify({"agent": agent})
    console.log(data);

    let urlProd = 'https://api.estimateur-immo.com/agent/getOne';

    var config = {
      method: 'post',
      url: urlProd,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (response.data == ""){
        this.setState({redirect: true});
      } else {
        this.setState({
          agent: response.data,
          url: agent
        })
      }
      
    })
    .catch((error) => {
      console.log(error);
      this.setState({redirect: true});
    });
    
  }

  redirecting = () => {
    if (this.state.redirect){
      return(
        <Navigate to='/404' />
      )
    }
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.area} >
        {this.redirecting()}
        
        <Container fluid className='containerEstimation' style={{marginTop: '100px'}}>
        
        <div>
        <Header logo={this.state.agent != null ? this.state.agent.logo : null} hide={true} agent={this.state.url} elevation={true}/> 
        <h1>Mentions légales</h1>
        
        Conformément aux dispositions de la loi n° 2004-575 du 21 juin 2004 pour la confiance en l’économie numérique, il est précisé aux utilisateurs du site fsc-france.com l’identité des différents intervenants dans le cadre de sa réalisation et de son suivi.<br /><br />
        <h2>EDITION DU SITE</h2>
        Le site guillaumecampello.com est édité par la société abc digital consulting, société par actions simplifiées au capital de 2000,00 euros, dont le siège social est situé à 5 allée de la tour 06560 Valbonne, immatriculée au registre du commerce et des sociétés sous le numéro d’identification unique 838 599 694 R.C.S Grasse.<br /><br />
        <h2>RESPONSABLE DE PUBLICATION</h2>
        Guillaume Campello <br /><br />
        <h2>HÉBERGEUR</h2>
        Le site guillaumecampello.com est hébergé par la société IONOS by 1&1.
        Adresse: 7 Place de la Gare, 57200 Sarreguemines.
        Le stockage des données personnelles des Utilisateurs est exclusivement réalisé sur les centre de données (« clusters ») localisés dans des Etats membres de l’Union Européenne de la société IONOS by 1&1.<br /><br />
        <h2>NOUS CONTACTER</h2>
        Par email : contact@abcdigitalconsulting.com<br /><br />
        <h2>CNIL</h2>
        Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 modifiée, l’Utilisateur dispose d’un droit d’accès, de modification et de suppression des informations collectées par abc digital consulting.<br /><br />
        Pour exercer ce droit, il reviendra à l’Utilisateur d’envoyer un message à l’adresse suivante : contact@abcdigitalconsulting.com

        </div>

        </Container>        
    </div>
    );
  }
}

export default withStyles(styles)(Mentions);