import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { default as pdp } from '../../../../../pdp_agent.jpg';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
var axios = require('axios');

export default function Step2Form(props) {
  const [nom, setNom] = useState('');
  const [prenom, setPrenom] = useState('');
  const [tel, setTel] = useState('');

  const makeRequest = (e) => {
    e.preventDefault();
    props.nextStep('afterForm');
    console.log("Request");
  }

  const onSubmit = (e) => {
    e.preventDefault();

    console.log("IN FORM");
    console.log(props.agent_id);

    var data = JSON.stringify({"lastname": nom, 'name': prenom, "tel": tel, "agentId": props.agent_id});

    console.log(data);
    var config = {
      method: 'post',
      url: 'https://api.estimateur-immo.com/estimation/add-lead',
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      props.nextStep('afterForm');
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  return (
    <Container fluid className="containerEstimation">
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
      <Card className='cardChat'>
          <Row>
            <Col xs={2} className='colCardChatImg'>
              <Card.Img className='cardChatImg' src={props.agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
            </Col>
            <Col xs={10} className='colCardChatBody'>
              <Card.Body className="cardChatBody">
                Parfait !<br />
                Je vous laisse remplir vos coordonnées dans le formulaire ci-dessous, afin que je puisse vous contacter rapidement par téléphone
              </Card.Body>
            </Col>
          </Row>
      </Card>
      </div>

      <Form style={{marginLeft: '15px', marginRight: '15px', marginTop: '20px'}} onSubmit={onSubmit}>
        <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
        <Row>
          <Form.Control type="text" style={{boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} placeholder="Nom *" value={nom} onChange={(e) => setNom(e.target.value)} required/>
        </Row>
        <Row style={{marginTop: '15px'}}>
          <Form.Control type="text" style={{boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} placeholder="Prénom *" value={prenom} onChange={(e) => setPrenom(e.target.value)} required/>
        </Row>
        <Row style={{marginTop: '15px'}}>
          <Form.Control type="tel" style={{boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} placeholder="Téléphone *" maxLength={10} value={tel} onChange={(e) => setTel(e.target.value)} required/>
        </Row>
        </div>

        <div data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000">
        <Row style={{display: 'flex', justifyContent: 'center',}}>
            <Button
            type="submit"
            variant="contained"
            style={{textTransform: 'none', paddingLeft: '15px', paddingRight: '15px', borderRadius: '20px', backgroundColor: '#00c695', borderColor: '#00c695', fontSize: '1.2rem', fontWeight: 700, boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', marginTop: '2.5vh', marginRight: '3vh', marginLeft: '3vh', width: '90%', padding: '11px 0'}}
            // onClick={makeRequest}
            >
            <div>Valider <ArrowForwardIcon /></div>
            </Button>
        </Row>
        </div>
      </Form>
    </Container>
  )
}