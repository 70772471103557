import React from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import { Typography, Button, Icon, TextField } from "@mui/material";
import { Row, Container, Card, Form  } from 'react-bootstrap'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete from '@mui/material/Autocomplete';
import { PropTypes } from "prop-types";
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Header from '../WelcomePage/Header/Header';
import GoogleMaps from '../WelcomePage/Input/Input'
import DangerousIcon from '@mui/icons-material/Dangerous';
 import '../WelcomePage/welcome.css'
 import '../StepperPage/index.css'

import logo from '../../contact-icon.svg'
var axios = require('axios');

const styles = {
  area : {
    background: "linear-gradient(to  bottom, white, rgba(255,255,255,0.9), #FFCE00)",
    // background: "-webkit-linear-gradient(to left, #8f94fb, #4e54c8)",
    width: '100vw', 
    minHeight: "100vh",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: '20px'
},

input : {
  padding: '15px',
  height: "6vh",
  marginTop: "5vh", 
  fontSize: "19px",
  justifyContent: 'end',
  /* font-style: italic; */
  "@media (max-width: 900px)": {
    width: "80vw", 
  },
  width: "40vw", 
   border: 'none',
   outline: 'none', 
  borderRadius: '5rem'
},
  circles : {
    position: 'absolute',
    top: 0,

    left: 0,
    width: '100vw',
    height: "100vh",
    overflow: "hidden",
},
  buttonn: {
    borderRadius: '20px',
    backgroundColor: '#00c695',
    textDecoration: 'none', 
    borderColor: '#00c695',
    fontWeight: 700,
    boxShadow: '4 5 17px 5 rgb(0 0 0 / 46%)'
  },

};

class Page404 extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      tel: '',
      objet: '',
      msg: '',
      agent: null,
      error: null,
      send: false
    };
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.area} >
        <div className='circles'>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </div>
          {/* <Header logo={this.state.agent != null ? this.state.agent.logo : null} hide={true}/>  */}
          <Container fluid className='containerEstimation'>
          <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
            <Row style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{width: '89px', height: '89px', backgroundColor: '#EC8080', borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                {/* <img src={logo} style={{verticalAlign: 'middle', maxWidth: '100%'}} />   */}
                <DangerousIcon style={{maxWidth: '100%'}} fontSize='large'/>
              </div>
            </Row>
            <Row style={{marginTop: '10px'}}>
              <Typography textAlign='center' color='#EC8080' fontWeight={700}>
                Erreur 404 ! La page à laquelle vous essayez d'accéder n'existe pas.
              </Typography>  
            </Row>  
            {/* <Row style={{marginTop: '15px'}}>
              <Typography textAlign='center' color='#8a8a98' fontWeight={500}>
              Remplissez les champs ci-dessous pour prendre contact avec notre agence
              </Typography>
            </Row> */}
            </div>
          </Container>        
        </div>
    );
  }
}

export default withStyles(styles)(Page404);