import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { default as pdp } from '../../../../../pdp_agent.jpg';
import { Button, Stack } from '@mui/material';
import '../../../../StepperPage/index.css';

export default function Step2T2Proprio(props) {
  const agent = props.agent;

  const onClickButton = (e) => {
    console.log("click");
    switch (e.target.name) {
      case 'oui':
        props.nextStep('form')
        break;
      case 'notSur':
        props.nextStep('calendly')
        break;
      case 'no':
        props.nextStep('finish');
        break;
      default:
        break;
    }
    
  }

  return (
    <Container fluid className='containerEstimation'>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <Card className='cardChat' style={{marginBottom: '15px'}}>
                <Row>
                    <Col xs={2} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={props.agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={10} className='colCardChatBody'>
                        <Card.Body className="cardChatBody">
                          Cette fourchette de prix est assez large. Pour une estimation plus précise, il est recommandé de consulter un agent immobilier qui peut se déplacer. Seriez-vous intéressé pour en discuter ?<br />
                          Notez déjà mon n° de téléphone personnel : {agent.tel}<br/>
                          Voici également mon mail : {agent.email}
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
      </div>

      <Stack spacing={2} direction="column" alignItems="center" marginTop="20px" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
        <Button variant="text" onClick={onClickButton} name="oui" style={{width: '100%', padding: '17px 10px', borderRadius: '10px', boxShadow: '0 0 9px 0 rgb(0 0 0 / 17%)', backgroundColor: '#fff', fontSize: '1rem', fontWeight: 600, lineHeight: 1.38, textAlign: 'center', color: '#4e4e61', border: 'none', width: '100%', height: '100%', textTransform: 'none', fontFamily: 'SourceSansPro,sans-serif'}}>
            Oui, je suis partant pour avoir une discussion à ce sujet
        </Button>
        <Button variant="text" onClick={onClickButton} name="notSur" style={{width: '100%', padding: '17px 10px', borderRadius: '10px', boxShadow: '0 0 9px 0 rgb(0 0 0 / 17%)', backgroundColor: '#fff', fontSize: '1rem', fontWeight: 600, lineHeight: 1.38, textAlign: 'center', color: '#4e4e61', border: 'none', width: '100%', height: '100%', textTransform: 'none', fontFamily: 'SourceSansPro,sans-serif'}}>
            Ok pour organiser une rencontrer.
        </Button>
        <Button variant="text" onClick={onClickButton} name="no" style={{width: '100%', padding: '17px 10px', borderRadius: '10px', boxShadow: '0 0 9px 0 rgb(0 0 0 / 17%)', backgroundColor: '#fff', fontSize: '1rem', fontWeight: 600, lineHeight: 1.38, textAlign: 'center', color: '#4e4e61', border: 'none', width: '100%', height: '100%', textTransform: 'none', fontFamily: 'SourceSansPro,sans-serif'}}>
            Je vous remercie, mais je préfère pour le moment avoir la possibilité de vous contacter si besoin
        </Button>
      </Stack>
    </Container>
  )
}