import logo from './logo.svg';
import WelcomePage from './Components/WelcomePage/WelcomePage';
import StepperPage from './Components/StepperPage';
import React, { useEffect } from 'react'
import { Routes, Route, useLocation } from 'react-router-dom';
import EstimationPage from './Components/EstimationPage';
import Testing from './Components/Testing';
import ContactUs from './Components/WelcomePage/ContactUs';
import Connexion from './Components/Connexion/index';
import SignUp from './Components/SignUp';
import Page404 from './Components/404/index';
import Mentions from './Components/Mentions';
import Politique from './Components/Politique';
import ReactPixel from 'react-facebook-pixel';

// function usePageViews() {
//   let location = useLocation();
//   console.log(location)
//   // React.useEffect(() => {
//   //   ga.send(["pageview", location.pathname]);
//   // }, [location]);
// }

function App() {
  // usePageViews();
  const location = useLocation();

  

  // useEffect(() => {
  //   const options = {
  //     autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  //     debug: true, // enable logs
  //   };
  
  //   // var pixel = "<script>!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '274305991563918');fbq('track', 'PageView');</script><noscript><img height=\"1\" width=\"1\" style=\"display:none\"src=\"https://www.facebook.com/tr?id=274305991563918&ev=PageView&noscript=1\"/></noscript>";
  //   var data;
  //   var pixel1 = '274305991563918';
  //   console.log(ReactPixel.fbq);
  //   const advancedMatching = { em: 'gcampello@abcdigitalconsulting.com' };
  //   ReactPixel.init(pixel1, advancedMatching, options);
  //   ReactPixel.pageView();
  //   console.log(ReactPixel.fbq);
  //   // ReactPixel.track(event, data);
  // })

  

  


  return (
    <div>
      <Routes>
      {/* <Route path="/" element={<WelcomePage />} /> */}
        <Route path="/:id" element={<WelcomePage location={location} />}  />
        <Route path="/estimation/:id" element={<StepperPage location={location}/>} />
        <Route path="/finish/:name/:id" element={<EstimationPage location={location} />} />
        <Route path="/:id/contact-us" element={<ContactUs location={location} />} />
        <Route path=":id/mentions" element={<Mentions location={location} />}/>
        <Route path=":id/politique" element={<Politique location={location}/>}/> 
        <Route path="/connexion" element={<Connexion />}/>
        <Route path="/register" element={<SignUp />}/>
        <Route path="/404" element={<Page404 />} />
        <Route path="*" element={<Page404 />} />
        <Route path="/testing" element={<Testing />} />
      </Routes>
      {/* <WelcomePage /> */}
    </div>
  );
}

export default App;
