import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button, Typography } from '@mui/material';
import { Link } from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { default as pdp } from '../../../../pdp_agent.jpg';
import '../../index.css';
var axios = require('axios');

export default function Step11(props) {
  const [tel, setTel] = useState('');
  const [clicked, setClicked] = useState(false);

  const { agent } = props;

  const makeRequest = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() == false){
      console.log('false');
    } else {
      if (!clicked){
        setClicked(true);
        const numero = "+33" + tel;
        props.nbNum(tel);

        let urlProd = 'https://api.estimateur-immo.com/contact/send-sms';


        //var data = JSON.stringify({"tel": numero});
        var data = JSON.stringify({"tel": numero , "agenceName": agent.agence_name});

        var config = {
          method: 'post',
          url: urlProd,
          headers: {
            'Content-Type': 'application/json'
          },
          data : data
        };
        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
            props.nextStep(13);
          })
          .catch(function (error) {
            console.log(error);
          });
        // props.nextStep(14);
      }
    }
  }

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='messag1'>
        <Card className='cardChat'>
            <Row>
                <Col xs={2} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>
                      Pour recevoir votre estimation par SMS, indiquez votre numéro de téléphone
                    </Card.Body>
                </Col>
            </Row>
        </Card>
      </div>

      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='messag2'>
      <Form style={{marginLeft: '15px', marginRight: '15px', marginTop: '20px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}} onSubmit={makeRequest}>
        <Row style={{display: 'flex', justifyContent: 'center'}}>
          <Form.Control required style={{maxWidth: '500px' , boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} minLength={10} type="tel" placeholder="Téléphone" maxLength={10} value={tel} onChange={(e) => setTel(e.target.value)} />
        </Row>

        <Row className='rowNextButton' style={{marginTop: '30px'}}>
            <Button
            type="submit"
            variant="contained"
            endIcon={<ArrowForwardIcon/>}
            // style={{marginTop: '20px', maxWidth: '450px', textTransform: 'none', paddingLeft: '18px', paddingRight: '18px', fontSize: '1.5rem', backgroundColor: '#00c695', borderColor: '#00c695', lineHeight: 1.13, borderRadius: '15px', padding: '15.5px 24px', boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', whiteSpace: 'nowrap'}}
            className='receiveButton'
            // onClick={makeRequest}
            >
            Recevoir mon estimation
            </Button>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
          <Typography variant="caption" display="block" gutterBottom style={{fontSize: '0.6rem'}}>
          Cette estimation est entièrement gratuite. En renseignant vos coordonnées, vous acceptez d'être contacté pour l'estimation de votre bien immobilier ainsi que notre <Link to={`/${props.url}/politique`}>Politique de confidentialité</Link>. Pour toute question, veuillez nous contacter à l'adresse suivante: {agent.email}.
          Ces données ne seront pas communiquées à des tiers.
          </Typography>
        </Row>
      </Form>
      </div>
    </Container>
  )
}
