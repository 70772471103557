import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { default as pdp } from '../../../../pdp_agent.jpg';
import '../../../StepperPage/index.css';

export default function Step1T2(props) {
  return (
    <Container fluid className="containerEstimation">
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <Card className='cardChat' style={{marginBottom: '15px'}}>
                <Row>
                    <Col xs={2} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={props.agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={10} className='colCardChatBody'>
                        <Card.Body className="cardChatBody">
                        Notre estimateur automatisé indique que le prix de votre bien devrait se situer entre :
                        <div style={{width: 'max-content', padding: '7px 25px', backgroundColor: '#90B9F2', borderRadius: '12px', margin: '5px 0', display: 'inline-block'}}>
                            {props.price_min} € et {props.price_max} €
                        </div>
                        <br />Cette fourchette de prix ne comprend pas d'éventuelles places de parking
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
      </div>

      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
            <Card className='cardChat'>
                <Row>
                    <Col xs={2} className='colCardChatImg'>
                    </Col>
                    <Col xs={10} className='colCardChatBody'>
                        <Card.Body className="cardChatBody">
                          Cette estimation vous convient-elle ?
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
      </div>
      

      <Stack spacing={2} direction="column" alignItems="center" marginTop="20px" data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000">
        <Button variant="text" onClick={props.nextStep} name="oui" style={{width: '100%', padding: '17px 10px', borderRadius: '10px', boxShadow: '0 0 9px 0 rgb(0 0 0 / 17%)', backgroundColor: '#fff', fontSize: '1rem', fontWeight: 600, lineHeight: 1.38, textAlign: 'center', color: '#4e4e61', border: 'none', width: '100%', height: '100%', textTransform: 'none', fontFamily: 'SourceSansPro,sans-serif'}}>
          Oui, je suis satisfait de cette estimation
        </Button>
        <Button variant="text" onClick={props.nextStep} name="notSur" style={{width: '100%', padding: '17px 10px', borderRadius: '10px', boxShadow: '0 0 9px 0 rgb(0 0 0 / 17%)', backgroundColor: '#fff', fontSize: '1rem', fontWeight: 600, lineHeight: 1.38, textAlign: 'center', color: '#4e4e61', border: 'none', width: '100%', height: '100%', textTransform: 'none', fontFamily: 'SourceSansPro,sans-serif'}}>
          Je suis indécis, je ne peux pas me prononcer
        </Button>
        <Button variant="text" onClick={props.nextStep} name="no" style={{width: '100%', padding: '17px 10px', borderRadius: '10px', boxShadow: '0 0 9px 0 rgb(0 0 0 / 17%)', backgroundColor: '#fff', fontSize: '1rem', fontWeight: 600, lineHeight: 1.38, textAlign: 'center', color: '#4e4e61', border: 'none', width: '100%', height: '100%', textTransform: 'none', fontFamily: 'SourceSansPro,sans-serif'}}>
          Non, cette estimation ne correspond pas à mes attentes
        </Button>
      </Stack>
    </Container>
  )
}