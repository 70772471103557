import { Container, Row, Col, Card, Form, CardGroup, ToggleButton, Navbar } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProgressBarStepper from '../ProgressBar';
import { Stack } from '@mui/material';
import { useState, useEffect } from 'react';

export default function Footer(props){
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
        return window.innerWidth < 992
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
        window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  return (
    <>
    {!mobileView ?
    <footer style={{position: 'absolute', padding: '10px 0px 15px 0px', top: 0, width: '100%', zIndex: 1, backgroundColor: 'hsla(0,0%,95%,.8)', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
    {/* OLD style={{bottom: 0}} */}
    <Container style={{maxWidth: '900px', margin: 'auto'}}>
      <Row>
        <Col xs={1} style={{textAlign: 'right', padding: '0'}}>
          {props.hide ? <ArrowBackIcon onClick={props.previousStep} style={{color: 'transparent'}} fontSize="large"/> : <ArrowBackIcon onClick={props.previousStep} style={{color: '#c0c0c6'}} fontSize="large"/>}
        </Col>
        <Col xs={11} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
          <ProgressBarStepper percent={props.percent} />
        </Col>
      </Row>
      <Row>
        <Col xs={1}></Col>
        <Col xs={11}>
          {/* <Row style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Col>1. Votre bien</Col>
            <Col>2. Description</Col>
            <Col>3. Détails</Col>
            <Col>4. Estimation</Col>
          </Row> */}
          <Stack direction="row" justifyContent="space-between">
            <div style={{fontSize: '15px', fontWeight: 700, lineHeight: 2.39, color: props.step == 1 ? '#FFCE00' : '#c0c0c6'}}>Étape 1: Votre bien</div>
            <div style={{fontSize: '15px', fontWeight: 700, lineHeight: 2.39, color: props.step == 2 ? '#FFCE00' : '#c0c0c6'}}>Étape 2: Description</div>
            <div style={{fontSize: '15px', fontWeight: 700, lineHeight: 2.39, color: props.step == 3 ? '#FFCE00' : '#c0c0c6'}}>Étape 3: Détails</div>
            <div style={{fontSize: '15px', fontWeight: 700, lineHeight: 2.39, color: props.step == 4 ? '#FFCE00' : '#c0c0c6'}}>Étape 4: Estimation</div>
          </Stack>
        </Col>
      </Row>
    </Container>
    </footer>
    : null}
    </>
  );
}
