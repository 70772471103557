import React from 'react';
import { Container, Row, Col, Card, Form, CardGroup, ToggleButton, Navbar } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import Step1T1 from './Step1/T1';
import Step2Calendly from './Step2/T1/Step2Calendly';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Step2Form from './Step2/T1/Step2Form';
import Step1T2 from './Step1/T2';
import Step2T2NonProprio from './Step2/T2/NonProprio';
import Step2T2Proprio from './Step2/T2/Proprio';
import Step3 from './Step3';
var axios = require('axios');

class EstimationPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      contact: 'tel', //tel ou mail
      proprio: false,
      step: 1,
      previous_step: 1,
      type: null, //1 -> choisir date de rdv / etre rappelé (tel), 2 -> oui plutot bien / pas d'avis / non ca ne colle pas (mail)
      price_min: null,
      price_max: null,
      agent_id: null,
      price: 0,
      agent: null,
      redirect: false
    }
  }

  getAgentInfos = () => {
    var data = JSON.stringify({"agent": this.props.location.pathname.split('/')[2]})
    console.log(data);

    //  let urlProd = 'https://api.esti-immo.fr/agent/getOne';
    let urlProd = 'https://api.estimateur-immo.com/agent/getOne';

    let urlDev = "http://localhost:3004/agent/getOne";

    var config = {
      method: 'post',
      url: urlProd,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      this.setState({
        agent: response.data,
      })
    })
    .catch((error) => {
      console.log(error);
      this.setState({redirect: true})
    });
  }

  componentDidMount(){
    this.getAgentInfos();
    const id = this.props.location.pathname.split('/')[3];
    // const id = "622f655defae9ca83144d334";
    console.log("ID: " + id)
    const data = JSON.stringify({'id': id});

    let urlProd = 'https://api.estimateur-immo.com/estimation/get';
    //let urlProd = 'https://api.estimateur-immo.com/agent/getOne';

    let urlDev = "http://localhost:3004/estimation/get";

    const config = {
      method: 'post',
      url: urlProd,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
      .then((res) => {
        let price_min = parseInt(res.data.virtual_price_min);
        const price_max = parseInt(res.data.virtual_price_max);


        // Calculer le delta initial
        let delta = price_max - price_min;

        // Si le delta est supérieur à 100000, ajuster price_min
        if (delta > 100000) {
            price_min = price_max - 100000;
        }


        const price = parseInt(res.data.price);

        const proprio = res.data.proprietaire == 'oui' ? true : false;

        console.log("TYPE: " + res.data.type);


        if (proprio == true) {

          const script = document.createElement("script");

          script.innerHTML = `
              !function(f,b,e,v,n,t,s)
              {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
              n.callMethod.apply(n,arguments):n.queue.push(arguments)};
              if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
              n.queue=[];t=b.createElement(e);t.async=!0;
              t.src=v;s=b.getElementsByTagName(e)[0];
              s.parentNode.insertBefore(t,s)}(window, document,'script',
              'https://connect.facebook.net/en_US/fbevents.js');
              fbq('init', '242491718805755');
              fbq('track', 'Proprio');
          `;

          document.body.appendChild(script);

        }

        // const type =
        this.setState({ price_min: price_min, price_max: price_max, agent_id: res.data.agent._id, price: price, type: res.data.type, proprio: proprio})
        console.log(JSON.stringify(res.data));
      }).catch((error) => {
        this.setState({redirect: true})
        console.log(error);
      });







  }

  displayStepType1 = () => {
    const { price_min, price_max, agent_id, price, agent } = this.state;
    switch (this.state.step) {
        case 1:
            return <Step1T1 nextStep={this.nextStepT1} price_min={price_min} price_max={price_max} price={price} agent={agent}/>
            break;
        case 2:
            // return <Step2 nextStep={this.nextStep}/>
            return <Step2Form nextStep={this.nextStepT1} agent_id={agent_id} agent={agent}/>
            break;
        case 3:
            return <Step2Calendly agent={agent} agent_id={agent_id}/>
            break;
        case 4:
            return <Step3 agent={agent} agent_id={agent_id}/>
            break;
        default:
            break;
    }
  }

  displayStepType2 = () => {
    const { proprio, price_min, price_max, agent, agent_id } = this.state;
    switch (this.state.step) {
      case 1:
        return <Step1T2 nextStep={this.nextStepT2} price_min={price_min} price_max={price_max} agent={agent} agent_id={agent_id} />
        break;
      case 2:
        if (proprio == true){
          return <Step2T2Proprio nextStep={this.nextStepT2} agent={agent} agent_id={agent_id}/>
        } else {
          return <Step2T2NonProprio agent={agent} agent_id={agent_id}/>
        }
        break;
      case 3:
        return <Step2Form nextStep={this.nextStepT2} agent={agent} agent_id={agent_id}/>
        break;
      case 4:
        return <Step2Calendly nextStep={this.nextStepT2} agent={agent} agent_id={agent_id}/>
        break;
      case 5:
        return <Step3 agent={agent} agent_id={agent_id}/>
        break;
      default:
        break;
    }
  }

  nextStepT1 = (value) => {
    const currentStep = this.state.step;
    if (value == 'calendly'){
      this.setState({step: 3, previous_step: currentStep});
    } else if (currentStep  == 2){
      this.setState({step: 4});
    } else {
      this.setState({step: currentStep + 1})
    }
  }

  nextStepT2 = (value) => {
    console.log(value);
    const currentStep = this.state.step;
    switch (value) {
      case 'calendly':
        this.setState({step: 4, previous_step: currentStep});
        break;
      case 'form':
        this.setState({step: 3, previous_step: currentStep});
        break;
      case 'afterForm':
        this.setState({step: 5});
        break;
      case 'finish':
        return;
        break;
      default:
        this.setState({step: currentStep + 1, previous_step: currentStep})
        break;
    }
  }

  previousStep = () => {
    const currentStep = this.state.step;
    if (currentStep == this.state.previous_step){
      this.setState({step: currentStep - 1});
    } else {
      this.setState({step: this.state.previous_step});
    }
  }

  redirecting = () => {
    if (this.state.redirect){
      return(
        <Navigate to='/404' />
      )
    }
  }

  render() {
    const { type, price_min, price_max, step, agent } = this.state;

    return (
      <div style={{backgroundColor: '#f6f6f6', height: '100vh'}}>
        {this.redirecting()}
        {/* <Navbar bg="white" style={{marginBottom: '15px'}}>
            <Container fluid style={{display: 'flex', flexDirection: 'column'}}>
                <Navbar.Brand width="100%" style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center'}} href="#" onClick={this.previousStep}>
                    {this.state.step > 1 ? <ArrowBackIcon fontSize='small'  style={{color: '#5a94e7'}}/> : null}
                    <div style={{fontSize: '1rem', fontWeight: 700, lineHeight: 2.39, fontFamily: 'SourceSansPro,sans-serif', display: 'block', color: '#5a94e7', marginLeft: '20px', textAlign: 'center'}}>Votre estimation</div>
                </Navbar.Brand>
            </Container>
        </Navbar> */}
        <Navbar bg="#f6f6f6" style={{}} className="navBarr">
        <Container fluid style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingLeft: '0px', paddingRight: '0px', backgroundColor: 'white'}}>
            <Navbar.Brand style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: '14px', paddingBottom: '14px'}} href="#" onClick={this.previousStep}>
                <ArrowBackIcon fontSize='small'  style={{color: '#5a94e7', marginRight: 'auto', marginLeft: '20px', visibility: step > 1 ? 'visible' : 'hidden'}}/>
                <div style={{fontSize: '1rem', color: '#5a94e7', marginLeft: '20px', position: 'fixed', fontWeight: 700}}>Estimation</div>
            </Navbar.Brand>
            {/* <ProgressBarStepper percent={props.percent}/> */}
        </Container>
      </Navbar>
        { price_min != null && price_max != null && agent != null ?
        <div>
        {type == 1 ? this.displayStepType1() : this.displayStepType2()}
        </div>
        : <div>Chargement... </div>
        }
      </div>
    )
  }
}

export default EstimationPage;
