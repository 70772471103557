import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button, Typography } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { default as pdp } from '../../../../../pdp_agent.jpg';
import '../../../index.css';
import { Link } from "react-router-dom";
var axios = require('axios');

export default function Step11MailA(props) {
  const [mail, setMail] = useState('');
  const [clicked, setClicked] = useState(false);

  const { agent } = props;

  const makeRequest = (e) => {
    e.preventDefault();
    // const numero = "+33" + tel;
    // props.nbNum(numero);

    let urlProd = 'https://api.estimateur-immo.com/estimation/send';


    if (!clicked){
      setClicked(true);
      var data = JSON.stringify({email: mail, action: 2, data: props.data});
      var config = {
      method: 'post',
      url: urlProd,
      headers: {
          'Content-Type': 'application/json'
      },
      data : data
      };
      axios(config)
      .then(function (response) {
      console.log(JSON.stringify(response.data));
      props.nextStep(15);
      })
      .catch(function (error) {
      console.log(error);
      });
    }
  }

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='confirm1'>
        <Card className='cardChat'>
            <Row>
                <Col xs={2} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody"  style={{ fontSize: '1.6rem' , fontWeight: '650'}}>
                      Pour recevoir votre estimation par mail, indiquez votre e-mail
                    </Card.Body>
                </Col>
            </Row>
        </Card>
      </div>

      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='confirm2'>
      <Form style={{marginLeft: '15px', marginRight: '15px', marginTop: '20px'}} onSubmit={makeRequest}>
        <Row style={{display: 'flex', justifyContent: 'center'}}>
          <Form.Control required style={{maxWidth: '500px' , boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} type="mail" placeholder="Adresse mail" value={mail} onChange={(e) => setMail(e.target.value)} />
        </Row>

        <Row style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
            <Button
            type="submit"
            variant="contained"
            endIcon={<ArrowForwardIcon />}

            // style={{marginTop: '20px', maxWidth: '450px', textTransform: 'none', paddingLeft: '18px', paddingRight: '18px', backgroundColor: '#00c695', borderColor: '#00c695', lineHeight: 1.13, borderRadius: '15px', padding: '15.5px 24px', boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)'}}

            // style={{paddingLeft: '18px', maxWidth: '450px', paddingRight: '18px', fontSize: '1.5rem', backgroundColor: '#00c695', borderColor: '#00c695', lineHeight: 1.13, fontWeight: 700, paddingTop: '15.5px', paddingBottom: '15.5px', borderRadius: '15px', boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)'}}
            className='receiveButton'


            // style={{textTransform: 'none', paddingLeft: '15px', paddingRight: '15px', borderRadius: '20px', backgroundColor: '#00c695', borderColor: '#00c695', fontSize: '1.2rem', fontWeight: 700, boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', marginTop: '2.5vh', marginRight: '3vh', marginLeft: '3vh', width: '90%', padding: '11px 0', whiteSpace: 'nowrap'}}
            // onClick={makeRequest}
            >
            Recevoir mon estimation
            </Button>
        </Row>
        <Row style={{display: 'flex', justifyContent: 'center', marginTop: '20px'}}>
        <Typography variant="caption" display="block" gutterBottom style={{fontSize: '0.6rem'}}>
        Cette estimation est entièrement gratuite. En renseignant vos coordonnées, vous acceptez d'être contacté pour l'estimation de votre bien immobilier ainsi que notre <Link to={`/${props.url}/politique`}>Politique de confidentialité</Link>. Pour toute question, veuillez nous contacter à l'adresse suivante: {agent.email}.
          Ces données ne seront pas communiquées à des tiers.
        </Typography>
        </Row>
      </Form>
      </div>
    </Container>
  )
}
