import { Container, Col, Row, Card,  } from 'react-bootstrap';
import { Button, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
// import { default as pdp } from '../../../pdp_agent.jpg';
import { default as appart } from '../../../appart.png';
import { default as maiso } from '../../../maison.png';
import { default as dupl } from '../../../duplex.png';
import { default as cha } from '../../../chateau.png';
import { useState } from 'react';
import '../index.css';


export default function Step1(props) {
    const [maison, setMaison] = useState(false);
    const [appartement, setAppartement] = useState(false);
    const [duplex, setDuplex] = useState(false);
    const [chateau, setCastle] = useState(false);


    const sleep = (milliseconds) => {
      return new Promise(resolve => setTimeout(resolve, milliseconds))
    }

    const onSubmitHome = async() => {
      if (!maison){
        props.modifLieu('maison');
        setMaison(true);
        props.addToData({propertyType: 1});
        await sleep(1000);
        props.nextStep(2);
      }
    }

    const onSubmitAppart = async() => {
      if (!appartement){
        props.modifLieu('appart');
        setAppartement(true);
        props.addToData({propertyType: 0});
        await sleep(1000);
        props.nextStep(2);
      }
    }


    const onSubmitCastle = async() => {
      if (!maison){
        props.modifLieu('maison');
        setCastle(true);
        props.addToData({propertyType: 1});
        await sleep(1000);
        props.nextStep(2);
      }
    }

    const onSubmitDuplex = async() => {
      if (!maison){
        props.modifLieu('appart');
        setDuplex(true);
        props.addToData({propertyType: 0});
        await sleep(1000);
        props.nextStep(2);
      }
    }

    const displayAgenceName = () => {
      return props.agent.agence_name.replaceAll('_', ' ');
    }

    const { agent } = props;


    return (
        console.log("PDP: " + props.agent.pdp),
        <Container fluid className='containerEstimation' style={props.style()}>
         <Row>
            <Col xs={12}>
              <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true'>
                <Card className='cardChat'>
                    <Row>
                        <Col xs={12} className='colCardChatImg'>
                            <Card.Img className='cardChatImg' src={agent.pdp} width="80px" height="80px" alt="pdp_agent"/>
                        </Col>
                        {/*<Col xs={12} className='colCardChatBody'>
                            <Card.Body className="cardChatBody" style={{ fontSize: '1.2rem' , fontWeight: '650'}}>Bonjour !<br /> Je m'appelle {agent.name} {agent.lastname}. Je suis agent immobilier et spécialiste de votre secteur chez {displayAgenceName()}.</Card.Body>
                        </Col>*/}
                    </Row>
                </Card>
              </div>

              <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true'>
                <Card className='cardChat' style={{marginTop: '10px'}}>
                    <Row>
                        {/*<Col xs={0} className='colCardChatImg'>
                        </Col>*/}
                        <Col xs={12} className='colCardChatBody'>
                            <Card.Body className="cardChatBody">Je vais réaliser l'estimation de votre bien. Pour y parvenir, voici quelques questions.</Card.Body>
                        </Col>
                    </Row>
                </Card>
              </div>

              <div data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" data-aos-once='true'>
                <Card className='cardChat' style={{marginTop: '10px'}}>
                    <Row>
                        <Col xs={12} className='colCardChatBody'>
                            <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>Quel est votre type de bien ?</Card.Body>
                        </Col>
                    </Row>
                </Card>
              </div>

              <div data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='typeHome'>
                {/* // <Stack direction="row" justifyContent='center' style={{marginTop: '20px'}}>*/}
                <Row style={{marginTop: '40px'}}>
                  <Col xs={6} sm={6} lg={3} xl={3} className='colGroupCardRightt'>

                    <div
                      className='containerCard'
                      onClick={onSubmitHome}
                    >
                      <label className='labelCard'>
                        <input type='radio' className='inputCard' />
                        <div className='container2Card'>
                          <span className='okCard'>
                            {maison ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                          </span>
                          <div className='containerImgCard'>
                            <img className='imgCard' src={maiso} />
                          </div>
                          <p className='titleCard'>
                            <span className='spanTitleCard'>Maison</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} lg={3} xl={3} className='colGroupCardLeftt'>
                    <div
                      className='containerCard'
                      onClick={onSubmitAppart}
                    >
                      <label className='labelCard'>
                        <input type='radio' className='inputCard' />
                        <div className='container2Card'>
                          <span className='okCard'>
                            {appartement ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                          </span>
                          <div className='containerImgCard'>
                            <img className='imgCard' src={appart} />
                          </div>
                          <p className='titleCard'>
                            <span className='spanTitleCard'>Appartement</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} lg={3} xl={3} className='colGroupCardRightt'>
                    <div
                      className='containerCard'
                      onClick={onSubmitDuplex}
                    >
                      <label className='labelCard'>
                        <input type='radio' className='inputCard' />
                        <div className='container2Card'>
                          <span className='okCard'>
                            {duplex ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                          </span>
                          <div className='containerImgCard'>
                            <img className='imgCard' src={dupl} />
                          </div>
                          <p className='titleCard'>
                            <span className='spanTitleCard'>Duplex</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  </Col>
                  <Col xs={6} sm={6} lg={3} xl={3} className='colGroupCardLeftt'>
                    <div
                      className='containerCard'
                      onClick={onSubmitCastle}
                    >
                      <label className='labelCard'>
                        <input type='radio' className='inputCard' />
                        <div className='container2Card'>
                          <span className='okCard'>
                            {chateau ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                          </span>
                          <div className='containerImgCard'>
                            <img className='imgCard' src={cha} />
                          </div>
                          <p className='titleCard'>
                            <span className='spanTitleCard'>Chateau</span>
                          </p>
                        </div>
                      </label>
                    </div>
                  </Col>
                 {/*</Stack>*/}
                </Row>
              </div>
            </Col>
           {/* <Col xs={3}>
             <img src='https://images.unsplash.com/photo-1541628951107-a9af5346a3e4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=989&q=80' style={{ height: '100%', width: '25%' }} alt='Background' />
            </Col>*/}
          </Row>
        </Container>
    )
}
