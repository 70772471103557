// import React, {useState } from 'react';
// import { Container, Card, Row, Col } from 'react-bootstrap';
// import { default as pdp } from '../../pdp_agent.jpg';
// import { Transition, CSSTransition } from 'react-transition-group';
// import './index.css'

// class Testing extends React.Component{
//   render() {
//     return (
//       <div style={{backgroundColor: '#f6f6f6', height: '100vh'}}>
//         <Container>
//             <div data-aos="fade-up" data-aos-delay="50" data-aos-duration="1000">
//             <Card style={{border: 'none', backgroundColor: '#f6f6f6'}}>
//                 <Row>
//                     <Col xs={2}>
//                         <Card.Img style={{borderRadius: '100%', border: '3px solid #5a94e7', objectFit: 'cover', width: '54px', height: '54px'}} src={pdp} width="54px" height="54px" alt="pdp_agent"/>
//                     </Col>
//                     <Col xs={10}>
//                         <Card.Body style={{fontSize: '1rem', borderRadius: '15px', backgroundColor: '#5a94e7', color: 'white', marginLeft: '15px', fontWeight: 350, lineHeight: 1.5}}>Bonjour !<br /> Je m'appelle Alain Castano, spécialiste immobilier de votre secteur chez Amiltone Immobilier. Pour réaliser l'estimation, j'aurais quelques questions à vous poser.</Card.Body>
//                     </Col>
//                 </Row>
//             </Card>
//             </div>
//             <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000">
//             <Card style={{border: 'none', backgroundColor: '#f6f6f6'}}>
//                 <Row>
//                     <Col xs={2}>
//                         <Card.Img style={{borderRadius: '100%', border: '3px solid #5a94e7', objectFit: 'cover', width: '54px', height: '54px'}} src={pdp} width="54px" height="54px" alt="pdp_agent"/>
//                     </Col>
//                     <Col xs={10}>
//                         <Card.Body style={{fontSize: '1rem', borderRadius: '15px', backgroundColor: '#5a94e7', color: 'white', marginLeft: '15px', fontWeight: 350, lineHeight: 1.5}}>Bonjour !<br /> Je m'appelle Alain Castano, spécialiste immobilier de votre secteur chez Amiltone Immobilier. Pour réaliser l'estimation, j'aurais quelques questions à vous poser.</Card.Body>
//                     </Col>
//                 </Row>
//             </Card>
//             </div>
//         </Container>
//       </div>
//     )
//   }
// }


// export default Testing ;


import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Container, Button, Alert } from 'react-bootstrap';
import { CSSTransition } from 'react-transition-group';

import './index.css';

export default function Testing() {
  const [showButton, setShowButton] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  return (
    <Container style={{ paddingTop: '2rem' }}>
      {showButton && (
        <Button
          onClick={() => setShowMessage(true)}
          size="lg"
        >
          Show Message
        </Button>
      )}
      <CSSTransition
        in={showMessage}
        timeout={300}
        classNames="alert"
        unmountOnExit
        onEnter={() => setShowButton(false)}
        onExited={() => setShowButton(true)}
      >
        <Alert
          variant="primary"
          dismissible
          onClose={() => setShowMessage(false)}
        >
          <Alert.Heading>
            Animated alert message
          </Alert.Heading>
          <p>
            This alert message is being transitioned in and
            out of the DOM.
          </p>
          <Button onClick={() => setShowMessage(false)}>
            Close
          </Button>
        </Alert>
      </CSSTransition>
    </Container>
  );
}