import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button } from '@mui/material';
import { default as pdp } from '../../../../pdp_agent.jpg';
import '../../../StepperPage/index.css';

export default function Step1T1(props) {
  return (
    <Container fluid className="containerEstimation">
      {/* <Card style={{border: 'none', backgroundColor: '#f6f6f6'}}>
        <Row>
            <Col xs={2}>
                <Card.Img style={{borderRadius: '100%', border: '3px solid #5a94e7', objectFit: 'cover', width: '54px', height: '54px'}} src={pdp} width="54px" height="54px" alt="pdp_agent"/>
            </Col>
            <Col xs={10}>
                <Card.Body style={{fontSize: '1rem', borderRadius: '15px', backgroundColor: '#5a94e7', color: 'white', marginLeft: '15px', fontWeight: 350, lineHeight: 1.5}}>
                    Le prix de votre bien, d'après notre estimateur automatisé, devrait se situer entre :
                    <div style={{width: 'max-content', padding: '7px 25px', backgroundColor: '#90B9F2', borderRadius: '12px', margin: '5px 0', display: 'inline-block'}}>
                        {props.price_min} € et {props.price_max} €
                    </div>
                </Card.Body>
            </Col>
        </Row>
      </Card> */}
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <Card className='cardChat'>
                <Row>
                    <Col xs={2} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={props.agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={10} className='colCardChatBody'>
                        <Card.Body className="cardChatBody">
                        Notre estimateur automatisé indique que le prix de votre bien devrait se situer entre :
                        <div style={{width: 'max-content', padding: '7px 25px', backgroundColor: '#90B9F2', borderRadius: '12px', margin: '5px 0', display: 'inline-block'}}>
                            {props.price_min} € et {props.price_max} €
                            {/* {props.price} € */}
                        </div>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
      </div>

      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
            <Card className='cardChat' style={{marginTop: '15px'}}>
                <Row>
                    <Col xs={2} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={props.agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={10} className='colCardChatBody'>
                        <Card.Body className="cardChatBody">
                        Pour obtenir une estimation plus précise, il est préférable d'en discuter. Je vous propose de planifier un rendez-vous.<br />
                        Ce rendez-vous est sans engagement.
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
      </div>

      {/* <Card style={{border: 'none', backgroundColor: '#f6f6f6', marginTop: '15px'}}>
        <Row>
            <Col xs={2}>
                <Card.Img style={{borderRadius: '100%', border: '3px solid #5a94e7', objectFit: 'cover', width: '54px', height: '54px'}} src={pdp} width="54px" height="54px" alt="pdp_agent"/>
            </Col>
            <Col xs={10}>
                <Card.Body style={{fontSize: '1rem', borderRadius: '15px', backgroundColor: '#5a94e7', color: 'white', marginLeft: '15px', fontWeight: 350, lineHeight: 1.5}}>
                    Pour obtenir une estimation plus précise, il est préférable d'en discuter. Je vous propose de planifier un rendez-vous.<br />
                    Ce rendez-vous est sans engagement.
                </Card.Body>
            </Col>
        </Row>
      </Card> */}

    <div data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000">
      <Row style={{display: 'flex', justifyContent: 'center'}}>
            <Button
            type="submit"
            variant="contained"
            style={{textTransform: 'none', paddingLeft: '15px', paddingRight: '15px', borderRadius: '15px', backgroundColor: '#00c695', borderColor: '#00c695', fontSize: '1.1rem', fontWeight: 700, boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', marginTop: '2.5vh', marginRight: '3vh', marginLeft: '3vh', width: '90%', padding: '11px 0', whiteSpace: 'nowrap'}}
            onClick={() => props.nextStep('calendly')}
            >
                Choisir une date de rendez-vous
            </Button>
      </Row>

      <Row style={{display: 'flex', justifyContent: 'center'}}>
            <Button
            type="submit"
            variant="contained"
            style={{textTransform: 'none', paddingLeft: '15px', paddingRight: '15px', borderRadius: '15px', backgroundColor: '#00c695', borderColor: '#00c695', fontSize: '1.1rem', fontWeight: 700, boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', marginTop: '2.5vh', marginRight: '3vh', marginLeft: '3vh', width: '90%', padding: '11px 0', whiteSpace: 'nowrap'}}
            onClick={() => props.nextStep('form')}
            >
                Être rappelé
            </Button>
      </Row>
    </div>
    </Container>
  );
}