import { Button, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { Grid, Box, TextField, Link } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HttpsIcon from '@mui/icons-material/Https';
import { Card, Row } from 'react-bootstrap';
import { default as logo } from '../../imo_logo.svg';

class SignUp extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      step: 1,
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      lastname: '',
      description: '',
      tel: '',
      agence_name: '',
      adresse: '',
      host_email: '',
      port_email: '',
      username_email: '',
      password_email: '',
      error: null
    }
    this.paperStyle = { padding: 20, width: 280};
    this.btnStyle = {margin: '8px 0', backgroundColor: '#01203F', marginTop: '30px'}
  }

  editInfo = (e) => {
    const key = e.target.id;
    const value = e.target.value;
    this.setState({[key]: value})
  }

  importFile = (e) => {
    console.log(e.target.files);
  }

  returnStep = () => {
    switch (this.state.step) {
        case 1:
            return this.Step1();
            break;
        case 2: 
            return this.Step2();
            break;
        case 3:
            return this.Step3();
            break;
        case 4:
            return this.Step4();
            break;
        case 5:
            return this.Step5();
            break;
        default:
            break;
    }
  }

  nextStep = (e) => {
    e.preventDefault();
    console.log(this.state);
    switch (e.target.name) {
        case 'step1':
            this.checkStep1();
            break;
        case 'step2':
            this.checkStep2();
            break;
        case 'step3':
            this.checkStep3();
            break;
        case 'step4':
            this.checkStep4();
            break
        default:
            break;
    }
  }

  checkStep1 = () => {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const { email, password, confirmPassword } = this.state;

    if (re.test(email)){
      if (password.length > 4){

        if (password === confirmPassword){
          const nextStep = this.state.step + 1;
          this.setState({step: nextStep, error: null});
        } else {
          const error = 'Les deux mots de passes ne sont pas identiques';
          this.setState({error: error});
        }

      } else {
        const error = "Votre mot de passe doit contenir au moins 5 caractères";
        this.setState({error: error});
      } 

    } else {
      const error = "Votre adresse mail est invalide";
      this.setState({error: error});
    }
  }

  checkStep2 = () => {
    const { name, lastname, description, tel, error } = this.state;
    if (name.length > 0 && lastname.length > 0 && description.length > 0){

      if (tel.length == 10){
        const newStep = this.state.step + 1;
        this.setState({step: newStep, error: null});
      } else {
        const error = "Le numéro de téléphone est invalide";
        this.setState({error: error});
      }

    } else {
      const error = "Veuillez renseigner tous les champs ci-dessus";
      this.setState({error: error});
    }
  }

  checkStep3 = () => {
    const { agence_name, adresse, error } = this.state;
    if (agence_name.length > 0 && adresse.length > 0){
      const newStep = this.state.step + 1;
      this.setState({step: newStep, error: null});
    } else {
      const error = "Veuillez renseigner tous les champs ci-dessus";
      this.setState({error: error});
    }
  }

  checkStep4 = () => {
    const { host_email, port_email, username_email, password_email, error } = this.state;
    if (host_email.length > 0 && port_email.length > 0 && username_email.length > 0 && password_email.length > 0){
      const newStep = this.state.step + 1;
      this.setState({step: newStep});
    } else {
      const error = "Veuillez renseigner tous les champs ci-dessus";
      this.setState({error: error});
    }
  }

  Step1 = () => {
    const { email, password, confirmPassword, error } = this.state;
    return (
        <Paper elevation={10} style={this.paperStyle}>
            <Grid align='center'>
            <img src={logo} width='50px' height='50px'/>
            <h3>Sign Up</h3>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'flex-end'}} mt={2}>
                <AccountBoxIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="email" label="Email" variant="standard" value={email} onChange={this.editInfo}  fullWidth required/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} mt={2}>
                <HttpsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="password" label="Password" type='password' variant="standard" value={password} onChange={this.editInfo} fullWidth required/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} mt={2}>
                <HttpsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="confirmPassword" label="ConfirmPassword" type='password' variant="standard" value={confirmPassword} onChange={this.editInfo} fullWidth required/>
            </Box>
            {error != null ? <Typography mt={1} color='red'>{error}</Typography> : null}
            <Button style={this.btnStyle} type="submit" variant="contained" name='step1' onClick={this.nextStep} fullWidth>Suivant</Button>
        </Paper>
    )
  }

  Step2 = () => {
    const { name, lastname, description, tel, error } = this.state;
    return (
        <Paper elevation={10} style={this.paperStyle}>
            <Grid align='center'>
            <img src={logo} width='50px' height='50px'/>
            <h3>Sign Up</h3>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'flex-end'}} mt={2}>
                <AccountBoxIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="lastname" label="Nom" variant="standard" value={lastname} onChange={this.editInfo} fullWidth required/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} mt={2}>
                <HttpsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="name" label="Prenom" variant="standard" value={name} onChange={this.editInfo} fullWidth required/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} mt={2}>
                <HttpsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="description" label="Description" variant="standard" value={description} onChange={this.editInfo} fullWidth required/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} mt={2}>
                <HttpsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="tel" label="Tel" type='number' maxLength={10} variant="standard" value={tel} onChange={this.editInfo} fullWidth required/>
            </Box>
            <Box sx={{display: 'flex', alignItems: 'flex-end'}} mt={2}>
                <Button
                variant="contained"
                component="label"
                >
                Photo de profil
                <input
                    type="file"
                    id='images'
                    onChange={this.importFile}
                    hidden
                />
                </Button>
            </Box>
            {error != null ? <Typography mt={1} color='red'>{error}</Typography> : null}
            <Button style={this.btnStyle} type="submit" variant="contained" name='step2' onClick={this.nextStep} fullWidth>Suivant</Button>
        </Paper>
    )
  }

  Step3 = () => {
    const { agence_name, adresse, error } = this.state;
    return (
        <Paper elevation={10} style={this.paperStyle}>
            <Grid align='center'>
            <img src={logo} width='50px' height='50px'/>
            <h3>Sign Up</h3>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'flex-end'}} mt={2}>
                <AccountBoxIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="agence_name" label="Nom de l'agence" variant="standard" value={agence_name} onChange={this.editInfo} fullWidth required/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} mt={2}>
                <HttpsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="adresse" label="Adresse" variant="standard" value={adresse} onChange={this.editInfo} fullWidth required/>
            </Box>
            {error != null ? <Typography mt={1} color='red'>{error}</Typography> : null}
            <Button style={this.btnStyle} type="submit" variant="contained" name='step3' onClick={this.nextStep} fullWidth>Suivant</Button>
        </Paper>
    )
  }

  Step4 = () => {
    const { host_email, port_email, username_email, password_email, error} = this.state;
    return (
        <Paper elevation={10} style={this.paperStyle}>
            <Grid align='center'>
            <img src={logo} width='50px' height='50px'/>
            <h3>Sign Up</h3>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'flex-end'}} mt={2}>
                <AccountBoxIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="host_email" label="Host_email" variant="standard" value={host_email} onChange={this.editInfo} fullWidth required/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} mt={2}>
                <HttpsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="port_email" label="Port_email" type="number" variant="standard" value={port_email} onChange={this.editInfo} fullWidth required/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end'}} mt={2}>
                <AccountBoxIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="username_email" label="User_email" variant="standard" value={username_email} onChange={this.editInfo} fullWidth required/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end'}} mt={2}>
                <AccountBoxIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
                <TextField id="password_email" label="Password_email" type="password" variant="standard" value={password_email} onChange={this.editInfo} fullWidth required/>
            </Box>
            {error != null ? <Typography mt={1} color='red'>{error}</Typography> : null}
            <Button style={this.btnStyle} type="submit" variant="contained" name='step4' onClick={this.nextStep} fullWidth>S'inscrire</Button>
        </Paper>
    )
  }

  Step5 = () => {
    return (
        <Paper elevation={10} style={this.paperStyle}>
            <Grid align='center'>
            <img src={logo} width='50px' height='50px'/>
            <h3>Sign Up</h3>
            </Grid>
            <Typography mt={3}>
              Votre inscription a bien été enregistrée !
            </Typography>
        </Paper>
    )
  }

  render() {
    return(
      <Grid style={{backgroundColor: '#EBEDEF', height: '100vh'}} align='center' justifyContent='center' container alignItems='center'>
        {this.returnStep()}
      </Grid>
    )
  }
}

export default SignUp;