import React from 'react';
import { Container, Row, Col, Card, Form, CardGroup, ToggleButton, Navbar } from 'react-bootstrap';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import ProgressBar from './ProgressBar';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { default as logo2 } from '../../logo_cg_1.svg'
import { default as pdp } from '../../pdp_agent.jpg';
import { default as appart } from '../../appart.png';
import { default as maison } from '../../maison.png';
import {Navigate, Route, } from 'react-router-dom';
import { Button, Icon } from '@mui/material';
import Step1 from './Step1';
import Step2 from './Step2_1';
import Step2a from './Step2/Step2a';
import Step2b from './Step2/Step2b';
import Step2c from './Step2/Step2c';
import Step3 from './Step3';
import Step4a from './Step4/Step4a';
import Step4b from './Step4/Step4b';
import Step4c from './Step4/Step4c';
import Step5 from './Step5';
import Step6 from './Step6';
import Step7 from './Step7';
import Step11 from './Step11/Step11Tel';
import Step8 from './Step8';
import Step12 from './Step12';
import Step13 from './Step13';
import Step9 from './Step9';
import Step10 from './Step10';
import Step11MailB from './Step11/Step11Mail/Step11MailB';
import Step11MailC from './Step11/Step11Mail/Step11MailC';
import Header from './Header';
import Footer from './Footer';
import Step11MailA from './Step11/Step11Mail/Step11MailA';
var axios = require('axios');

class StepperPage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      redirect: false,
      width: window.innerWidth,
      height: window.innerHeight,
      step: 1,
      maison: false,
      appartement: false,
      num: '',
      percent: 8,
      stepFooter: 1,
      allStep: [1],
      agent: null,
      url: null,
      name_agent_url: null,
      data: null,
      hideBackButton: false,
    };
  }

  containerEstimationStyle = () => {
    const { width, height } = this.state;
    const scalee = height * 1.32845 / 992;
    if (width > 992){
      return {
        marginTop: '30px',
        transform: `scale(${scalee})`,
        transformOrigin: 'center top',
      };
    }
  }

  redirecting = () => {
    if (this.state.redirect){
      const { url } = this.state;
      return(
        <Navigate to={url} />
      )
    }
  }

  makeRedirect = (id) => {
    const name = this.props.location.pathname.split('/')[2];
    const url = '/finish/' + name + '/' + id;

    console.log("ici url redirect")
    console.log(url)
    this.setState({url: url, redirect: true});


  }

  getPageMaxScroll() {
    // Cross browser page height detection is ugly
    return Math.max(
      document.body.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.clientHeight,
      document.documentElement.scrollHeight,
      document.documentElement.offsetHeight
    ) - window.innerHeight; // Subtract viewport height
  }


  componentDidMount(){
    let top = 1000000; // Value larger than maximum scroll
    const maxScroll = this.getPageMaxScroll();
    if (top > maxScroll) {
      top = maxScroll;
    }
    window.scrollTo(0, top);
    document.body.style.zoom = '100%';

    //let urlProd = "https://api.esti-immo.fr/agent/getOne";
    let urlProd = 'https://api.estimateur-immo.com/agent/getOne';

    let urlDev = "http://localhost:3004/agent/getOne";




    window.addEventListener('resize', this.updateWindowDimensions);
    const { location } = this.props;
    if (location.state == null || location.state.lon == null || location.state.lat == null || location.state.address == null){
      this.setState({redirect: true, url: '/404'});
    } else {
      this.setState({data: {lon: location.state.lon, lat: location.state.lat, adresse: location.state.address}});
      const name_url = this.props.location.pathname.split('/')[2];
      var data = JSON.stringify({"agent": name_url})
      var config = {
        method: 'post',
        url: urlProd,
        headers: {
          'Content-Type': 'application/json'
        },
        data : data
      };
      axios(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        if (response.data == ""){
          this.setState({url: '/404', redirect: true});
        } else {
          this.setState({
            agent: response.data,
            name_agent_url: name_url
          })
          this.addToData({agent_id: response.data._id})
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    }

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  addToData = (obj) => {
    const newData = Object.assign(this.state.data, obj);
    this.setState({data: newData});
  }

  modifNum = (nbNum) => {
    this.setState({num: nbNum});
  }

  modifLieu = (label) => {
    switch (label) {
      case 'maison':
        this.setState({maison: true, appart: false});
        break;
      case 'appart':
        this.setState({maison: false, appart: true});
        break
      default:
        break;
    }
  }


  returnStep(nbStep){
    const { agent, name_agent_url } = this.state;
    switch (nbStep) {
      case 1:
        return <Step1 nextStep={this.nextStep} modifLieu={this.modifLieu} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        break;
      case 2:
        // return <Step2 nextStep={this.nextStep}/>
        console.log(JSON.stringify(this.state.data));
        return <Step2a nextStep={this.nextStep} lieu={this.state.maison ? 'maison' : 'appart'} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        break;

      // rajout des etapes a b c pour step 2
      case 3:
        console.log(JSON.stringify(this.state.data));
        return <Step2b nextStep={this.nextStep} lieu={this.state.maison ? 'maison' : 'appart'} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        break;

      case 4:
        console.log(JSON.stringify(this.state.data));
        return <Step3 nextStep={this.nextStep} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        break;
      case 5:
        console.log(JSON.stringify(this.state.data));
        return <Step4a nextStep={this.nextStep} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        break;
      case 6:
        return <Step5 nextStep={this.nextStep} lieu={this.state.maison ? 'maison' : 'appart'} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        break;
      case 7:
        return <Step6 nextStep={this.nextStep} lieu={this.state.maison ? 'maison' : 'appart'} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        break;
      case 8:
        return <Step7 nextStep={this.nextStep} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        break;
      case 9:
        return <Step8 nextStep={this.nextStep} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        break;
      case 10:
        return <Step9 nextStep={this.nextStep} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        break;
      case 11:
        return <Step10 nextStep={this.nextStep} agent={agent} addToData={this.addToData} style={this.containerEstimationStyle}/>
        // return <Step11MailC nextStep={this.nextStep}/>
        break;
      case 12:
        return <Step11 nextStep={this.nextStep} nbNum={this.modifNum} agent={agent} url={name_agent_url} style={this.containerEstimationStyle}/>
        // return <Step12 nextStep={this.nextStep} />
        break;
      case 13:
        return <Step12 nextStep={this.nextStep} num={this.state.num} agent={agent} data={this.state.data} redirect={this.makeRedirect} style={this.containerEstimationStyle}/>
        break;
      case 14:
        console.log(JSON.stringify(this.state.data));
        return <Step11MailA nextStep={this.nextStep} agent={agent} data={this.state.data} url={name_agent_url} style={this.containerEstimationStyle}/>
        break;
      case 15:
        return <Step11MailB nextStep={this.nextStep} agent={agent} style={this.containerEstimationStyle}/>
        break;
      case 16:
        return <Step11MailC nextStep={this.nextStep} agent={agent} style={this.containerEstimationStyle}/>
        break;
      default:
        break;
    }
  }

  nextStep = (nbStep) => {
    var newAllStep = [...this.state.allStep, nbStep];
    switch (nbStep) {
      case 4:
        this.setState({step: nbStep, allStep: newAllStep, percent: 38, stepFooter: 2});
        break;
      case 7:
        this.setState({step: nbStep, allStep: newAllStep, percent: 65, stepFooter: 3});
        break;
      case 11:
        this.setState({step: nbStep, allStep: newAllStep, percent: 97, stepFooter: 4});
        break;
      default:
        this.setState({step: nbStep, allStep: newAllStep});
        break;
    }
    if (nbStep > 12){
      this.setState({hideBackButton: true});
    }

  }

  previousStep = () => {
    if (this.state.step > 1){
      var newAllStep = [...this.state.allStep];
      newAllStep.pop();
      const newStep = newAllStep[newAllStep.length - 1];

      switch (newStep) {
        case 3:
          this.setState({step: newStep, allStep: newAllStep, percent: 8, stepFooter: 1});
          break;
        case 4:
          this.setState({step: newStep, allStep: newAllStep, percent: 38, stepFooter: 2});
          break;
        case 7:
          this.setState({step: newStep, allStep: newAllStep, percent: 65, stepFooter: 3});
          break;
        case 11:
          this.setState({step: newStep, allStep: newAllStep, percent: 97, stepFooter: 4});
          break;
        default:
          this.setState({step: newStep, allStep: newAllStep});
          break;
      }
    }
  }

  render() {
    const { percent, step, agent, stepFooter, redirect, hideBackButton } = this.state;


    return (
      <>
      {this.redirecting()}
      {agent != null ?
      <div style={{backgroundColor: '#f6f6f6'}}>
        <Header percent={percent} previousStep={this.previousStep} logo={agent.logo} step={stepFooter} hide={hideBackButton}/>
        <Row>
          <Col lg={3} xs={0} id='colImgLeft' class='col-xs d-none'>
               <img src='https://images.pexels.com/photos/1080696/pexels-photo-1080696.jpeg?auto=compress&cs=tinysrgb&w=800' style={{ height: '85%', width: '135%' , marginTop: '-25px' }} alt='Background' />
          </Col>

          <Col lg={9} xs={12}>
            <TransitionGroup
            >
            <CSSTransition
              classNames="example"
              timeout={{ enter: 500, exit: 300 }}
              unmountOnExit
            >
            {this.returnStep(step)}
            </CSSTransition>
            </TransitionGroup>
          </Col>
        </Row>
        <Footer percent={percent} previousStep={this.previousStep} step={stepFooter} hide={hideBackButton}/>
      </div>
      : <div>Chargement... stepper</div>}
      </>
    )
  }
}

export default StepperPage;
