import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState, useEffect } from "react";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import { Link, }from 'react-router-dom';
import Drawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import { default as logo2 } from '../../../logo_cg_1.svg'

const useStyles = makeStyles(() => ({
    // header: {
    //   "@media (max-width: 900px)": {
    //     paddingLeft: 100,
    //   },
    // },
    appHeader :{
      /* background-color: #282c34; */
      height: '10vmin',
      display: 'flex',
      marginTop: '1vh',
      backgroundColor:'red',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: 'calc(10px + 2vmin)',
      color: 'white',
    },
    logo: {
    //   fontFamily: "Work Sans, sans-serif",
      // fontWeight: 600,
      // color: 'black',
      height: '6vh',
      // width: '170px',
      textAlign: "left",
    },
    logoMobile: {
      height: '35px',
      width: '85px',
      textAlign: "left"
    },
    menuButton: {
      fontWeight: 700,
      size: "18px",
      marginLeft: "38px"
    },
    toolbar: {
      display: "flex",
      justifyContent: "space-between",
    },
}));


export default function Header(props) {

  const [state, setState] = useState({
    mobileView: false,
    drawerOpen: false
  });

  const { mobileView, drawerOpen } = state;

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState((prevState) => ({ ...prevState, mobileView: true }))
        : setState((prevState) => ({ ...prevState, mobileView: false }));
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
      window.removeEventListener("resize", () => setResponsiveness());
    }
  }, []);

  const { header, logo, toolbar, menuButton, logoMobile, drawerContainer } = useStyles();

  const displayDesktop = () => {
    return <div
    style={{padding: 20}}
    >
    <Toolbar className={toolbar}>
        <Link style={{textDecoration: 'none',}} to={`/${props.agent}`}>
        <img src={props.logo} alt="logo" className={logo} />
        </Link>

              <div>{props.hide ? null : getMenuButtons()}</div>
           </Toolbar></div>
  };

  const displayMobile = () => {
    const handleDrawerOpen = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: true }));
    const handleDrawerClose = () =>
      setState((prevState) => ({ ...prevState, drawerOpen: false }));

    return (
      <div
      style={{padding: 20}}
      >

      <Toolbar className={toolbar}>

    {/* <img src={logo2} alt="logo" className={logo} /> */}
    <Link style={{textDecoration: 'none',}} to={`/${props.agent}`}>
    <img src={props.logo} alt="logo" className={logo} />
    </Link>
      {props.hide ? null : <>
        <IconButton
          {...{
            edge: "start",
            color: "inherit",
            "aria-label": "menu",
            "aria-haspopup": "true",
            onClick: handleDrawerOpen
          }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer
          {...{
            anchor: "right",
            open: drawerOpen,
            onClose: handleDrawerClose,
          }}
        >
          <div className={drawerContainer}>{getDrawerChoices()}</div>
        </Drawer>
        </>
  }
      </Toolbar>
      </div>
    );
  };

  const getDrawerChoices = () => {
    // return headersData.map(({ label, href }) => {
    //   return (
    //     <Link
    //       {...{
    //         component: Link,
    //         to: href,
    //         color: "inherit",
    //         style: { textDecoration: "none" },
    //         key: label,
    //       }}
    //     >
    //       <MenuItem><div style={{fontSize: '11px', fontWeight: 700}}>{label}</div></MenuItem>
    //     </Link>
    //   );
    // });
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
      {/*<Link style={{textDecoration: 'none'}} to={`/${props.agent}/contact-us`}>
        <Button className={menuButton} style={{fontWeight: 700, fontSize: '10.5px', marginLeft: '5px', marginRight: '20px', marginTop: '8px', color: 'black'}}>
          Contactez-nous
        </Button>
      </Link>*/}
      <Link style={{textDecoration: 'none'}} to={`/${props.agent}/mentions`}>
        <Button className={menuButton} style={{fontWeight: 700, fontSize: '10.5px', marginLeft: '5px', marginRight: '20px', marginTop: '8px', color: 'black'}}>
          Mentions légales
        </Button>
      </Link>
      </div>
    )
  };

  const getMenuButtons = () => {
    return (
      <div>
        <Link style={{textDecoration: 'none',}} to={`/${props.agent}/contact-us`}>
          {/*<Button className={menuButton} style={{fontWeight: 700, fontSize: '10.5px', marginLeft: '3px', color: 'black'}}>Contactez-nous</Button>*/}
        </Link>
        <Link style={{textDecoration: 'none',}} to={`/${props.agent}/mentions`}>
          <Button className={menuButton} style={{fontWeight: 700, fontSize: '10.5px', marginLeft: '3px', color: 'black'}}>Mentions légales</Button>
        </Link>
      </div>
    )
  };


  return (
    <header style={{position: 'absolute'}} >
      <AppBar color='transparent' style={{backgroundColor: props.elevation ? 'white' : 'hsla(0,0%,95%,.8)'}} elevation={props.elevation ? 2 : 0}>{mobileView ? displayMobile() : displayDesktop()}</AppBar>
    </header>
  );
}
