import React from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import { Typography, Button, Icon, TextField } from "@mui/material";
import { Row, Container, Card, Form  } from 'react-bootstrap'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete from '@mui/material/Autocomplete';
import { PropTypes } from "prop-types";
import { withStyles } from '@mui/styles';
import { Navigate } from 'react-router-dom';
import Header from '../Header/Header';
import GoogleMaps from '../Input/Input'
 import '../welcome.css'
 import '../../StepperPage/index.css'

import logo from '../../../contact-icon.svg'
var axios = require('axios');

const styles = {
  area : {
    background: "linear-gradient(to  bottom, white, rgba(210,213,219,0.9), #ACEFD1)",
    // background: "-webkit-linear-gradient(to left, #8f94fb, #4e54c8)",
    width: '100vw', 
    minHeight: "100vh",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: '20px'
},

input : {
  padding: '15px',
  height: "6vh",
  marginTop: "5vh", 
  fontSize: "19px",
  justifyContent: 'end',
  /* font-style: italic; */
  "@media (max-width: 900px)": {
    width: "80vw", 
  },
  width: "40vw", 
   border: 'none',
   outline: 'none', 
  borderRadius: '5rem'
},
  circles : {
    position: 'absolute',
    top: 0,

    left: 0,
    width: '100vw',
    height: "100vh",
    overflow: "hidden",
},
  buttonn: {
    borderRadius: '20px',
    backgroundColor: '#00c695',
    textDecoration: 'none', 
    borderColor: '#00c695',
    fontWeight: 700,
    boxShadow: '4 5 17px 5 rgb(0 0 0 / 46%)'
  },

};

class ContactUs extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      tel: '',
      objet: '',
      msg: '',
      agent: null,
      url: null,
      error: null,
      send: false,
      redirect: false,
    };
  }

  

  componentDidMount(){
    console.log(this.props);
    const agent = this.props.location.pathname.split('/')[1];
    console.log(agent);
    var data = JSON.stringify({"agent": agent})
    console.log(data);

    var urlProd = "api.estimateur-immo.com/agent/getOne";
    var config = {
      method: 'post',
      url: urlProd,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (response.data == ""){
        this.setState({redirect: true});
      } else {
        this.setState({
          agent: response.data,
          url: agent,
        })
      }
      
    })
    .catch((error) => {
      console.log(error);
      this.setState({redirect: true});
    });
    
  }

  redirecting = () => {
    if (this.state.redirect){
      return(
        <Navigate to='/404' />
      )
    }
  }

  onChange = (e) => {
    const value = e.target.value;
    console.log(this.state);
    switch (e.target.name) {
      case 'name':
        this.setState({name: value});
        break;
      case 'email':
        this.setState({email: value});
        break;
      case 'tel':
        this.setState({tel: value});
        break;
      case 'objet':
        this.setState({objet: value});
        break;
      case 'msg':
          this.setState({msg: value});
          break;  
      default:
        break;

      
    }
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { agent, name, email, tel, objet, msg } = this.state;
    var data = JSON.stringify({"name": name, "tel": tel, "agentId": agent._id, "message": msg});

    var urlProd = "api.estimateur-immo.com/estimation/add-lead";

    var config = {
      method: 'post',
      url: urlProd,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };

    axios(config)
    .then((response) => {
      this.setState({send: true, msg: '', email: '', name: '', tel: '', objet: '', message: ''});
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    const { classes } = this.props;
    const { address, lon, lat, name, tel, email, objet, msg, send, url } = this.state; 
    return (
      <div className={classes.area} >
        {this.redirecting()}
        {/* <div className='circles'>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
            </div> */}
          <Header logo={this.state.agent != null ? this.state.agent.logo : null} hide={true} agent={url}/> 
          <Container fluid className='containerEstimation' style={{marginTop: '100px'}}>
          <div data-aos="fade-up" data-aos-delay="200" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='contac1'>
            <Row style={{display: 'flex', justifyContent: 'center'}}>
              <div style={{width: '89px', height: '89px', backgroundColor: '#d6e6f7', borderRadius: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <img src={logo} style={{verticalAlign: 'middle', maxWidth: '100%'}} />  
              </div>
            </Row>
            <Row style={{marginTop: '10px'}}>
              <Typography textAlign='center' color='#5a94e7' fontWeight={700}>
                Contactez-nous  
              </Typography>  
            </Row>  
            <Row style={{marginTop: '15px'}}>
              <Typography textAlign='center' color='#8a8a98' fontWeight={500}>
              Remplissez les champs ci-dessous pour prendre contact avec notre agence
              </Typography>
            </Row>
            </div>
            <Form style={{marginLeft: '15px', marginRight: '15px', marginTop: '20px'}} onSubmit={this.onSubmit}>
              <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='contac2'>
              <Row style={{marginTop: '20px'}}>
                <Form.Control type="text" name='name' style={{boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} placeholder="Nom *" maxLength={14} value={name} onChange={this.onChange} required />
              </Row>
              <Row style={{marginTop: '20px'}}>
                <Form.Control type="email" name='email' style={{boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} placeholder="Adresse email *" value={email} onChange={this.onChange} required  />
              </Row>
              <Row style={{marginTop: '20px'}}>
                <Form.Control type="number" name='tel' style={{boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} placeholder="Téléphone *" maxLength={14} value={tel} onChange={this.onChange} required />
              </Row>
              <Row style={{marginTop: '20px'}}>
                <Form.Control type="text" name='objet' style={{boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} placeholder="Objet" maxLength={14} value={objet} onChange={this.onChange} required />
              </Row>
              <Row style={{marginTop: '20px'}}>
                <Form.Control as="textarea" rows={5} name='msg' style={{boxShadow: '0 0 5px 0 rgb(0 0 0 / 17%)', border: '1px solid #ced4da'}} placeholder="Votre message" value={msg} onChange={this.onChange} required />
              </Row>
              </div>
              {send ? <Typography textAlign='center' color='#00c695' style={{marginTop: '10px'}}>Votre demande a bien été envoyée.</Typography> : null}
              <div data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='contac3'>
              <Row style={{display: 'flex', justifyContent: 'center',}}>
                  <Button
                  type="submit"
                  variant="contained"
                  style={{textTransform: 'none', paddingLeft: '15px', paddingRight: '15px', borderRadius: '20px', backgroundColor: '#00c695', borderColor: '#00c695', fontSize: '1.2rem', fontWeight: 700, boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', marginTop: '3.5vh', marginRight: '3vh', marginLeft: '3vh', width: '90%', padding: '11px 0'}}
                  // onClick={makeReqsuest}
                  >
                  <div>Contactez-nous</div>
                  </Button>
              </Row>
              </div>
            </Form>
          </Container>        
        </div>
    );
  }
}

export default withStyles(styles)(ContactUs);