import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button, Stack } from '@mui/material';
import { default as pdp } from '../../../pdp_agent.jpg';
import sleeper from "../../../utils/sleeper";
import '../index.css';

import CheckIcon from '@mui/icons-material/Check';


import { default as diamant } from '../../../diamant.png';
import { default as outils } from '../../../outils.png';
import { default as yes } from '../../../yes.png';
import { default as no } from '../../../no.png';

export default function Step9(props) {
  const [displayMsg, setDisplayMsg] = useState(false);


   const [ouiProprio, setOuiProprio] = useState(false);
  const [nonProprio, setNonProprio] = useState(false);

  const onClickButton = async(e) => {
    if (!displayMsg){
      props.addToData({proprietaire: e.target.name});
      setDisplayMsg(true);
      await sleeper(3000);
      props.nextStep(11);
    }
  }



   const handleSubmit = () => {
     props.addToData({proprietaire: "oui"});
      props.nextStep(11);
  }

 const handleSubmit2 = () => {
     props.addToData({proprietaire: "non"});
      props.nextStep(11);
  }
  const { agent } = props;

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='prop1'>
        <Card className='cardChat'>
            <Row>
                <Col xs={12} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={12} className='colCardChatBody'>
                    <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>
                      Êtes-vous le propriétaire du bien ?
                    </Card.Body>
                </Col>
            </Row>
        </Card>
      </div>



       <div data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" data-aos-once='true'>
        <Stack direction="row" justifyContent='center' style={{marginTop: '20px'}}>
          {/* maxWidth: 992px */}
            <div
              className='containerCard'
              onClick={handleSubmit}
            >
              <label className='labelCard'>
                <input type='radio' className='inputCard' />
                <div className='container2Card'>
                  <span className='okCard'>
                    {ouiProprio ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                  </span>
                  <div className='containerImgCard'>
                    <img className='imgCard' src={yes} />
                  </div>
                  <p className='titleCard'>
                    <span className='spanTitleCard'>Oui</span>
                  </p>
                </div>
              </label>
            </div>
            <div
              className='containerCard'
               onClick={handleSubmit2}
            >
              <label className='labelCard'>
                <input type='radio' className='inputCard' />
                <div className='container2Card'>
                  <span className='okCard'>
                    {nonProprio ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                  </span>
                  <div className='containerImgCard'>
                    <img className='imgCard' src={no} />
                  </div>
                  <p className='titleCard'>
                    <span className='spanTitleCard'>Non</span>
                  </p>
                </div>
              </label>
            </div>
        </Stack>
      </div>



      {displayMsg ?
        <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='prop3'>
          <Card className='cardChat' style={{marginTop: '20px'}}>
              <Row>
                  <Col xs={2} className='colCardChatImg'>
                      <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                  </Col>
                  <Col xs={10} className='colCardChatBody'>
                      <Card.Body className="cardChatBody">
                        Bon d'accord !
                      </Card.Body>
                  </Col>
              </Row>
          </Card>
        </div>
      : null}
    </Container>
  )
}
