import { Button, IconButton, Typography } from "@mui/material";
import { Container, Col, Row, Card, FormGroup, Form, FormLabel } from "react-bootstrap";
import { default as pdp } from '../../../../pdp_agent.jpg';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from "@mui/system";
import { useState } from "react";
import '../../index.css';

export default function Step2a(props) {
    const [validated, setValidated] = useState(false);
    const [surface, setSurface] = useState(null);
    const [chambres, setChambres] = useState(null);
    const [pieces, setPieces] = useState(null);
    const [sdb, setSdb] = useState(null);
    const [eb, setEb] = useState(null);
    const [eti, setEti] = useState(null);
    const [error, setError] = useState(null);

    const { agent } = props;



    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            if (pieces >= chambres){
                const etageBien = props.lieu == 'maison' ? 0 : eb;
                const etageTotal = props.lieu == 'maison' ? 0 : eti;
                // props.addToData({propertySurface: surface, roomNb: pieces, bedroomNb: chambres, floor: etageBien, floorNb: etageTotal, sdb: sdb});
                props.addToData({propertySurface: surface , floor: etageBien, floorNb: etageTotal, sdb: sdb});

                props.nextStep(3);
            } else {
                event.preventDefault();
                setError("Le nombre de chambres ne peut pas être supérieur au nombre de pièces.");
            }
        }

        setValidated(true);

    }

    const addToValue = (label) => {
        switch (label) {
            case 'surface':
                setSurface(surface + 1);
                break;
            case 'chambres':
                setChambres(chambres + 1);
                break;
            case 'pieces':
                setPieces(pieces + 1);
                break;
            case 'sdb':
                setSdb(sdb + 1);
                break;
            case 'eb':
                setEb(eb + 1);
                break;
            case 'eti':
                setEti(eti + 1);
                break;
            default:
                break;
        }
    }

    const removeToValue = (label) => {
        switch (label) {
            case 'surface':
                if (surface > 0 && !isNaN(surface))
                  setSurface(surface - 1);
                break;
            case 'chambres':
                if (chambres > 0 && !isNaN(chambres))
                  setChambres(chambres - 1);
                break;
            case 'pieces':
                if (pieces > 0 && !isNaN(pieces))
                  setPieces(pieces - 1);
                break;
            case 'sdb':
                if (sdb > 0 && !isNaN(sdb))
                  setSdb(sdb - 1);
                break;
            case 'eb':
                if (eb > 0 && !isNaN(eb))
                  setEb(eb - 1);
                break;
            case 'eti':
                if (eti > 0 && !isNaN(eti))
                  setEti(eti - 1);
                break;
            default:
                break;
        }
    }

    return (
        console.log(props.lieu),
        <Container fluid className='containerEstimation' style={props.style()}>
          <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <Card className='cardChat'>
                <Row>
                    <Col xs={12} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={12} className='colCardChatBody'>
                        <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650' , textAlign: 'center'}}>Quels sont les caractéristiques <br />  de votre bien ?</Card.Body>
                    </Col>
                </Row>
            </Card>
          </div>

          <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Row>
                
                    <Row style={{marginTop: '40px', marginBottom: '20px'}}>
                        <Col xs={3}>
                            <div className='labelNb'>
                                <FormLabel>
                                Surface en m²
                                </FormLabel>
                            </div>
                        </Col>
                        <Col xs={9}>
                            <div className='containerPlusMoins'>
                                <div onClick={() => removeToValue('surface')}>
                                    <IconButton
                                      onClick={() => removeToValue('surface')}
                                      className='iconPlusMoins'
                                    >
                                        <RemoveIcon fontSize="small"/>
                                    </IconButton>
                                </div>
                                <Form.Control
                                  required
                                  type="number"
                                  value={surface}
                                  onChange={(e) => setSurface(Number(e.target.value))}
                                  className='inputPlusMoins'
                                />
                                <div onClick={() => addToValue('surface')}>
                                    <IconButton
                                      className='iconPlusMoins'
                                    >
                                    <AddIcon fontSize="small"/>
                                    </IconButton>
                                </div>
                            </div>
                        </Col>
                    </Row>
                
            </Row>

        {props.lieu == 'appart' ?
            <Row>
                <Row style={{marginTop: '20px'}}>
                    <Col xs={3}>
                        <div className='labelNb'>
                            <FormLabel>
                              Étage du bien
                            </FormLabel>
                        </div>
                    </Col>
                    <Col xs={9}>
                        <div className='containerPlusMoins'>
                            <div onClick={() => removeToValue('eb')}>
                                <IconButton className='iconPlusMoins'>
                                    <RemoveIcon fontSize="small"/>
                                </IconButton>
                            </div>
                            <Form.Control
                            required
                            type="number"
                            value={eb}
                            onChange={(e) => setEb(Number(e.target.value))}
                            className='inputPlusMoins'
                            />
                            <div onClick={() => addToValue('eb')}>
                                <IconButton className='iconPlusMoins'>
                                    <AddIcon fontSize="small"/>
                                </IconButton>
                            </div>
                        </div>
                    </Col>
                </Row>

                    <Row style={{marginTop: '20px'}}>
                        <Col xs={3}>
                            <div className='labelNb'>
                                <FormLabel>
                                Nbre étages bâtiment
                                </FormLabel>
                            </div>
                        </Col>
                        <Col xs={9}>
                            <div className='containerPlusMoins'>
                                <div onClick={() => removeToValue('eti')}>
                                    <IconButton className='iconPlusMoins'>
                                    <RemoveIcon fontSize="small"/>
                                    </IconButton>
                                </div>
                                <Form.Control
                                required
                                type="number"
                                value={eti}
                                onChange={(e) => setEti(Number(e.target.value))}
                                className='inputPlusMoins'
                                />
                                <div onClick={() => addToValue('eti')}>
                                <IconButton className='iconPlusMoins'>
                                <AddIcon fontSize="small"/>
                                </IconButton>
                                </div>
                            </div>
                        </Col>
                    </Row>

            </Row>
        : null}




            <Row style={{marginTop: '15px'}}>
                <Typography style={{color: 'red'}}>{error}</Typography>
            </Row>

            <Row className='rowNextButton'>
              <Button
                type="submit"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                className='nextButton'
              >
                Suivant
              </Button>
            </Row>
          </Form>
          </div>
        </Container>
    )
}
