import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button } from '@mui/material';
import { default as pdp } from '../../../../../pdp_agent.jpg';
import '../../../index.css';

export default function Step11MailC(props) {
  const { agent } = props;

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
     <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='mess1'>
        <Card className='cardChat'>
            <Row>
                <Col xs={2} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody"  style={{ fontSize: '1.6rem' , fontWeight: '650'}}>
                      Pour consulter la valeur de votre bien, cliquez sur le lien que vous avez reçu par SMS
                    </Card.Body>
                </Col>
            </Row>
        </Card>
      </div> 
      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='mess2'>
        <Card className='cardChat' style={{marginTop: '20px'}}>
            <Row>
                <Col xs={2} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody">
                    Si vous ne recevez pas votre estimation par tétéphone d'ici 15 minutes, vous pouvez nous contacter par téléphone au {agent.tel}
                    </Card.Body>
                </Col>
            </Row>
        </Card>
      </div> 
      <div data-aos="fade-up" data-aos-delay="700" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='mess3'>
        <Card className='cardChat' style={{marginTop: '10px'}}>
            <Row>
                <Col xs={2} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody">
                       Merci et à tout de suite.
                    </Card.Body>
                </Col>
            </Row>
        </Card>
      </div> 
    </Container>
  )
}