import { Container, Row, Col, Card, Form, CardGroup, ToggleButton, Navbar } from 'react-bootstrap';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ProgressBarStepper from '../ProgressBar';
import './index.css';
import { useState, useEffect } from 'react';
import { default as logo} from '../../../logoo.png';

export default function Header(props) {
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
      const setResponsiveness = () => {
          return window.innerWidth < 992
          ? setMobileView(true)
          : setMobileView(false);
      };

      setResponsiveness();
      window.addEventListener("resize", () => setResponsiveness());

      return () => {
          window.removeEventListener("resize", () => setResponsiveness());
      }
  }, []);

  const returnTitle = () => {
    switch (props.step) {
      case 1:
        return 'Étape 1: Votre bien';
        break;
      case 2:
        return 'Étape 2: Description';
        break;
      case 3:
        return 'Étape 3: Détails';
        break;
      case 4:
        return 'Étape 4: Estimation';
        break;
      case 5:
        return '';
        break;
      default:
        break;
    }
  }

  const displayHeaderMobile = () => {
    return (
    <Navbar bg="#f6f6f6" style={{}} className="navBarr">
        <Container fluid style={{display: 'flex', flexDirection: 'column', justifyContent: 'space-between', paddingLeft: '0px', paddingRight: '0px', backgroundColor: 'white'}}>
            <Navbar.Brand style={{width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', paddingTop: '14px', paddingBottom: '14px'}} href="#" onClick={props.previousStep}>
                {props.hide ? null : <ArrowBackIcon fontSize='small'  style={{color: '#FFCE00', marginRight: 'auto', marginLeft: '20px'}}/>}
                <div style={{fontSize: '1rem', color: '#FFCE00', marginLeft: '20px', position: 'fixed', fontWeight: 700}}>{returnTitle()}</div>
            </Navbar.Brand>
            <ProgressBarStepper percent={props.percent}/>
        </Container>
    </Navbar>
    );
  }

  const displayHeaderDesktop = () => {
    return (
      <header style={{position: 'relative', zIndex: 16, height: '120px'}}>
      <Container style={{padding: '0 15px'}}>
      <Navbar expand="lg" bg="#f6f6f6" style={{padding: '5px 0'}}>
        {/* <Navbar.Brand href="#" onClick={() => console.log('click')}>
            <img src={props.logo} style={{height: '100px', maxWidth: '213px', objectFit: 'contain', objectPosition: 'left'}} />
        </Navbar.Brand>*/}
      </Navbar>
      </Container>
      </header>
    );
  }

  return (
    <div data-aos="fade-in" data-aos-delay="50" data-aos-duration="1000">
        {mobileView ? displayHeaderMobile() : displayHeaderDesktop()}
    </div>
  );
}
