import { Button, Container, Paper, Typography } from '@mui/material';
import React from 'react';
import { Grid, Box, TextField, Link } from '@mui/material';
import AccountCircle from '@mui/icons-material/AccountCircle';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import HttpsIcon from '@mui/icons-material/Https';
import { default as logo } from '../../imo_logo.svg';

class Connexion extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      username: '',
      password: '',
      error: null
    }
    this.paperStyle = { padding: 20, width: 280};
    this.btnStyle = {margin: '8px 0', backgroundColor: '#01203F', marginTop: '30px'}
  }

  editInfo = (e) => {
    const key = e.target.id;
    const value = e.target.value;
    this.setState({[key]: value})
  }

  onSubmit = (e) => {
    e.preventDefault();
    const { username, password } = this.state;
    if (username.length > 0 && password.length > 0){
      console.log("Envoyer requete");
    } else {
      const error = "Veuillez renseigner tous les champs ci-dessus";
      this.setState({error: error});
    }
  }

  render() {
    const {username, password, error } = this.state;
    return(
      <Grid style={{backgroundColor: '#EBEDEF', height: '100vh'}} align='center' justifyContent='center' container alignItems='center'>
          <Paper elevation={10} style={this.paperStyle}>
            <Grid align='center'>
            <img src={logo} width='50px' height='50px'/>
            <h3>Connexion</h3>
            </Grid>
            <Box sx={{ display: 'flex', alignItems: 'flex-end'}} mt={2}>
              <AccountBoxIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField id="username" label="Username" variant="standard" value={username} onChange={this.editInfo} fullWidth required/>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }} mt={2}>
              <HttpsIcon sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <TextField id="password" label="Password" type='password' variant="standard" value={password} onChange={this.editInfo} fullWidth required/>
            </Box>
            {error != null ? <Typography mt={1} color='red'>{error}</Typography> : null}
            <Button style={this.btnStyle} type="submit" variant="contained" onClick={this.onSubmit} fullWidth>Login</Button>
            <Typography>
              <Link href="#">
                Forgot password ?
              </Link>
            </Typography>
          </Paper>
      </Grid>
    )
  }
}

export default Connexion;