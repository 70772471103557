import React from 'react';
import "react-step-progress-bar/styles.css";
import { ProgressBar } from 'react-step-progress-bar';

export default function ProgressBarStepper(props){
  return (
    <ProgressBar
      style={{borderRadius: 0}}
      percent={props.percent}
      filledBackground="#FFCE00"
      // width={811}
      width="100%"
      height={10}
    />
  );
}
