import { useState, useEffect } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { default as pdp } from '../../../pdp_agent.jpg';
import { default as va } from '../../../vis-a-vis.png';
import { default as fenet } from '../../../fenetre-sv.png';
import { default as sta } from '../../../star.png';
import { default as sta2 } from '../../../stars2.png';
import { default as sta3 } from '../../../stars3.png';
import '../index.css';

export default function Step7(props) {
  const [vav, setVav] = useState(false);
  const [svav, setSvav] = useState(false);
  const [bv, setBv] = useState(false);
  const [tbv, setTbv] = useState(false);
  const [ve, setVe] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const { agent } = props;

  useEffect(() => {
    const setResponsiveness = () => {
        return window.innerWidth < 374
        ? setMobileView(true)
        : setMobileView(false);
    };

    setResponsiveness();
    window.addEventListener("resize", () => setResponsiveness());

    return () => {
        window.removeEventListener("resize", () => setResponsiveness());
    }
    }, []);

  const onChangeView = (e) => {
    switch (e.target.name) {
      case 'vav':
        setVav(!vav);
        setSvav(false);
        break;
      case 'svav':
        setSvav(!svav);
        setVav(false);
        break;
      case 'bv':
        setBv(!bv);
        setTbv(false);
        setVe(false);
        break;
      case 'tbv':
        setBv(false);
        setTbv(!tbv);
        setVe(false);
        break;
      case 've':
        setBv(false);
        setTbv(false);
        setVe(!ve);
        break;
      default:
        break;
    }
  }

  const onSubmit = (e) => {
    if (svav && ve){
      props.addToData({view: 3});
    } else if (svav && (bv || tbv)){
      props.addToData({view: 2});
    } else if (vav){
      props.addToData({view: 1});
    } else {
      props.addToData({view: 0});
    }
    props.nextStep(9);
  }

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='vue1'>
        <Card className='cardChat'>
            <Row>
                <Col xs={12} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={12} className='colCardChatBody'>
                    <Card.Body className="cardChatBody">
                        Si votre logement dispose d'une belle vue, c'est un plus !<br />
                        Qu'est-ce qui décrit le mieux la vue de votre logement ?
                    </Card.Body>
                </Col>
            </Row>
        </Card>
      </div>
      
         
      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='vue2'>
          <Row style={{marginTop: '20px'}}>
              
            <Col xs={mobileView ? 12 : 6} sm={6} md={6} lg={4} className='colBV'>
              <div className='containerCardBV'>
                <label className='labelCard'>
                  <input type='radio' name='vav' className='inputCard' onClick={onChangeView}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {vav ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={va} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Vis-à-vis important</span>
                    </p>
                  </div>
                </label> 
              </div>
              </Col>

            <Col xs={mobileView ? 12 : 6} sm={6} md={6} lg={4} className='colBV'>
              <div className='containerCardBV'>
                <label className='labelCard'>
                  <input type='radio' name='svav' className='inputCard' onClick={onChangeView}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {svav ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={fenet} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Sans vis-à-vis</span>
                    </p>
                  </div>
                </label> 
              </div>
              </Col>
              <Col xs={mobileView ? 12 : 6} sm={6} md={6} lg={4} className='colBV'>
                <div className='containerCardBV'>
                    <label className='labelCard'>
                        <input type='radio' name='bv' className='inputCard' onClick={onChangeView}/>
                        <div className='container2Card'>
                            <span className='okCard'>
                            {bv ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                            </span>
                            <div className='containerImgCard'>
                            <img className='imgCard' src={sta} />
                            </div>
                            <p className='titleCard'>
                            <span className='spanTitleCard'>Belle vue</span>
                            <div style={{fontSize: '.750rem'}}>(lac, montagne, ...)</div>
                            </p>
                        </div>
                    </label> 
                </div>
              </Col>
              <Col xs={mobileView ? 12 : 6} sm={6} md={6} lg={6} xl={6} className='colBV'>
                <div className='containerCardBV'>
                    <label className='labelCard'>
                        <input type='radio' name='tbv' className='inputCard' onClick={onChangeView}/>
                        <div className='container2Card'>
                            <span className='okCard'>
                            {tbv ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                            </span>
                            <div className='containerImgCard'>
                            <img className='imgCard' src={sta2} />
                            </div>
                            <p className='titleCard'>
                            <span className='spanTitleCard'>Très belle vue</span>
                            <div style={{fontSize: '.750rem'}}>(campagne, toits …)</div>
                            </p>
                        </div>
                    </label> 
                </div>
              </Col>
              <Col xs={12} sm={12} md={12} lg={6} xl={6} className='colBV'>
                <div className='containerCardBV'>
                    <label className='labelCard'>
                        <input type='radio' name='ve' className='inputCard' onClick={onChangeView}/>
                        <div className='container2Card'>
                            <span className='okCard'>
                            {ve ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                            </span>
                            <div className='containerImgCard'>
                            <img className='imgCard' src={sta3} />
                            </div>
                            <p className='titleCard'>
                            <span className='spanTitleCard'>Vue exceptionnelle</span>
                            <div style={{fontSize: '.750rem'}}>(mer, Tour Eiffel, Mt Saint Michel, ...)</div>
                            </p>
                        </div>
                    </label> 
                </div>
              </Col>
          </Row>
      </div>

      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='vue3'>
      <Row className='rowNextButton'>
        <Button
        type="submit"
        onClick={onSubmit}
        variant="contained"
        endIcon={<ArrowForwardIcon />}
        style={{textTransform: 'none', borderRadius: '20px', backgroundColor: '#00c695', borderColor: '#00c695', fontSize: '1.5rem', fontWeight: 700, boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', marginTop: '1vh', maxWidth: '50%', padding: '11px 0'}}
        >
        Suivant
        </Button>
      </Row>
      </div>
    </Container>
  )
}