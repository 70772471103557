import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { default as pdp } from '../../../../../pdp_agent.jpg';
import { InlineWidget } from "react-calendly";


export default function Step2Calendly(props) {
  return (
    <Container fluid className="containerEstimation">
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
        <Card className='cardChat'>
          <Row>
            <Col xs={2} className='colCardChatImg'>
              <Card.Img className='cardChatImg' src={props.agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
            </Col>
            <Col xs={10} className='colCardChatBody'>
              <Card.Body className="cardChatBody" style={{marginBottom: '10px'}}>
                Parfait ! Je vous laisse choisir le jour et l’heure qui vous conviennent pour qu’on se rencontre. Ce rendez-vous est sans engagement.
              </Card.Body>
            </Col>
          </Row>
        </Card>
      </div>

      {/* <InlineWidget url="https://calendly.com/jeremymarciano/30min" /> */}
      <InlineWidget url={props.agent.url_calendly} />
    </Container>
  )
}