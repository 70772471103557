import { Button, Stack } from "@mui/material";
import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { default as pdp } from '../../../pdp_agent.jpg';
import '../index.css';

export default function Step10(props) {
  const [ouiMsg, setOuiMsg] = useState(false);
  const [notSurMsg, setNotSurMsg] = useState(false);
  const [noMsg, setNoMsg] = useState(false);

  const { agent } = props;

  const onClickButton = (e) => {
    const name = e.target.name;
    switch (name) {
        case 'oui':
            setOuiMsg(true);
            setNotSurMsg(false);
            setNoMsg(false);
            break;
        case 'notSur':
            setOuiMsg(false);
            setNotSurMsg(false);// avant true
            setNoMsg(false);
            break;
        case 'no':
            setOuiMsg(false);
            setNotSurMsg(false);
            setNoMsg(true);
            break;
        default:
            break;
    }
    console.log(e);
  }

  const onSubmit = (e) => {
    // e.preventDefault();
    const typeEstimation = (ouiMsg || notSurMsg) ? 1 : 2;
    const nbNextStep = (ouiMsg || notSurMsg) ? 12 : 14;
    console.log("TYPE: " + typeEstimation);
    console.log(ouiMsg);
    console.log(notSurMsg);
    console.log(noMsg);
    props.addToData({intentionDeVendre: e.target.name, type: typeEstimation});
    props.nextStep(nbNextStep);
  }

  const displayChoice = () => {
    if(ouiMsg){
      return (
        // <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
        <>
        <Row style={{width: '100%'}}>
          <Col xs={6}>
            <Button variant="text" name='Vente déjà commencée' onClick={onSubmit} className='intentionButton'>
                J'ai déjà<br />commencé
            </Button>
          </Col>

          <Col xs={6}>
            <Button variant="text" name='Dès que possible' onClick={onSubmit} className='intentionButton'>
                Dès que possible<br/>
            </Button>
          </Col>
        </Row>

        <Row style={{width: '100%'}}>
          <Col xs={6}>
            <Button variant="text" name='Dans moins de 3 mois' onClick={onSubmit} className='intentionButton'>
              D'ici 3 mois
            </Button>
          </Col>
          <Col xs={6}>
            <Button variant="text" name='Dans moins de 6 mois' onClick={onSubmit} className='intentionButton'>
                D'ici 6 mois
            </Button>
          </Col>
        </Row>
        <Row style={{width: '100%'}}>
          <Col xs={6}>
            <Button variant="text" name="Dans moins d'un an" onClick={onSubmit} className='intentionButton'>
                Dans plus de 6 mois
            </Button>
          </Col>
        </Row>

        </>
        // {/* // </div> */}
      )
    }
    else if (notSurMsg) {
      return (
        <>
        <Row style={{width: '100%'}}>
          <Col xs={6}>
            <Button variant="text" name='Situation devant évoluer dans moins de 12 mois' onClick={onSubmit} className='intentionButton'>
                Situation pouvant évoluer
            </Button>
          </Col>
          <Col xs={6}>
           <Button variant="text" name='Simple curiosité, avis ponctuel' onClick={onSubmit} className='intentionButton'>
            Simple curiosité, avis ponctuel
        </Button>
          </Col>
        </Row>
        </>
      );
    }
    else if (noMsg) {
   return (
        <>
        <Row style={{width: '100%'}}>
          <Col xs={6}>
            <Button variant="text" name='Situation devant évoluer dans moins de 12 mois' onClick={onSubmit} className='intentionButton'>
                Situation pouvant évoluer
            </Button>
          </Col>
          <Col xs={6}>
           <Button variant="text" name='Simple curiosité, avis ponctuel' onClick={onSubmit} className='intentionButton'>
            Simple curiosité, avis ponctuel
        </Button>
          </Col>
        </Row>
        </>
      );
    }
  }

  return (
    <Container fluid className='containerEstimation' style={{backgroundColor: '#f6f6f6'}} style={props.style()}>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='vendre1'>
        <Card className='cardChat'>
            <Row>
                <Col xs={2} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>
                      Envisagez-vous de vendre ?
                    </Card.Body>
                </Col>
            </Row>
        </Card>
      </div>

      <Stack spacing={2} direction="column" alignItems="center" marginTop="20px" data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='vendre2'>
        <Button variant="text" onClick={onClickButton} name="oui" className='intentionButton'>
            OUI
        </Button>
        {ouiMsg ? displayChoice() : null}

       <Button variant="text" onClick={onClickButton} name="no" className='intentionButton'>
            NON, ce n'est pas prévu.
        </Button>
        {noMsg ? displayChoice() : null}
      </Stack>
    </Container>
  )
}
