import React from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import { Typography, Button, Icon, TextField } from "@mui/material";
import { Row, Container, Card, Form  } from 'react-bootstrap'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete from '@mui/material/Autocomplete';
import { PropTypes } from "prop-types";
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import Header from '../WelcomePage/Header/Header';
import GoogleMaps from '../WelcomePage/Input/Input'
import DangerousIcon from '@mui/icons-material/Dangerous';
import { Navigate } from 'react-router-dom';
 import '../WelcomePage/welcome.css'
 import '../StepperPage/index.css'

import logo from '../../contact-icon.svg'
import '../StepperPage/index.css';
var axios = require('axios');

const styles = {
  area : {
    background: "rgba(210,213,219,0.4)",
    // background: "-webkit-linear-gradient(to left, #8f94fb, #4e54c8)",
    width: '100vw', 
    minHeight: "100vh",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: '20px'
},

input : {
  padding: '15px',
  height: "6vh",
  marginTop: "5vh", 
  fontSize: "19px",
  justifyContent: 'end',
  /* font-style: italic; */
  "@media (max-width: 900px)": {
    width: "80vw", 
  },
  width: "40vw", 
   border: 'none',
   outline: 'none', 
  borderRadius: '5rem'
},
  circles : {
    position: 'absolute',
    top: 0,

    left: 0,
    width: '100vw',
    height: "100vh",
    overflow: "hidden",
},
  buttonn: {
    borderRadius: '20px',
    backgroundColor: '#00c695',
    textDecoration: 'none', 
    borderColor: '#00c695',
    fontWeight: 700,
    boxShadow: '4 5 17px 5 rgb(0 0 0 / 46%)'
  },

};

class Politique extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      agent: null,
      url: null,
      error: null,
      redirect: false
    };
  }

  componentDidMount(){
    console.log(this.props);
    const agent = this.props.location.pathname.split('/')[1];
    console.log(agent);
    var data = JSON.stringify({"agent": agent})
    console.log(data);
    let urlProd = 'https://api.estimateur-immo.com/agent/getOne';

    var config = {
      method: 'post',
      url: urlProd,
      headers: { 
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (response.data == ""){
        this.setState({redirect: true});
      } else {
        this.setState({
          agent: response.data,
          url: agent
        })
      }
      
    })
    .catch((error) => {
      console.log(error);
      this.setState({redirect: true});
    });
    
  }

  redirecting = () => {
    if (this.state.redirect){
      return(
        <Navigate to='/404' />
      )
    }
  }


  render() {
    const { classes } = this.props;
    return (
      <div className={classes.area} >
        {this.redirecting()}
        
        <Container fluid className='containerEstimation' style={{marginTop: '100px'}}>
        
        <div>
            <Header logo={this.state.agent != null ? this.state.agent.logo : null} hide={true} agent={this.state.url} elevation={true}/> 
            <h1>Politique de confidentialité</h1>
            Soucieux du respect de la vie privée de ses Utilisateurs, abc digital consulting s’engage à assurer la protection des données personnelles de ses Utilisateurs. <br /><br />       
            Par conséquent, aucune donnée transmise par l’intermédiaire de notre site ne pourra être consultée par un tiers non autorisé. abc digital consulting prend toutes les précautions utiles pour préserver la sécurité de ces données et notamment, empêcher qu’elles soient déformées ou endommagées.<br /><br />
            abc digital consulting traite l’ensemble des données de ses Utilisateurs conformément à la présente politique de confidentialité.<br /><br />
            <h2>I. Définitions</h2>
            – donnée personnelle : toute information relative à une personne physique identifiée ou qui peut être identifiée, directement ou indirectement, par référence à un numéro d’identification ou à un ou plusieurs éléments qui lui sont propres.<br /><br />
            – Utilisateur : toute personne physique majeure capable, ou toute personne morale, utilisant les services qui sont proposés sur le Site legalpace.fr, pouvant être soit un particulier, soit un professionnel.<br /><br />
            <h2>II. Collecte et diffusion des données personnelles</h2>
            LegalPlace est susceptible de collecter des données personnelles liées à l’Utilisateur notamment lors :<br /><br />
            – de la navigation sur le Site<br /><br />
            – de la création d’un Document<br /><br />
            – d’une demande de renseignement ou de prise de contact<br /><br />
            Les informations susceptibles d’être collectées par abc digital consulting sont celles ayant trait à l’identité de l’Utilisateur, à ses coordonnées postales et/ou électroniques, à un numéro de carte bancaire ou encore à des données de connexion.<br /><br />
            Toutes les données à caractère personnel recueillies par abc digital consulting sont obligatoires et nécessaires pour bénéficier des Services proposés par abc digital consulting. Chaque donnée collectée par la Société est en accord avec la ou les finalités poursuivies.<br /><br />
            L’ensemble des données personnelles ainsi recueillies par abc digital consulting peuvent être transmises à des prestataires extérieurs afin d’assurer le bon fonctionnement des Services.<br /><br />
            <h2>III. Conservation des données personnelles</h2>
            Les données personnelles de l’Utilisateur sont conservées jusqu’à leur suppression à la demande de l’Utilisateur ou par la Société.<br /><br />
            abc digital consulting conservera dans ses systèmes informatiques et dans des conditions raisonnables de sécurité une preuve de la transaction comprenant le bon de commande et la facture conformément aux dispositions du Code de commerce relatives à la durée de conservation des livres et documents créés à l’occasion d’activités commerciales et du Code de la consommation relatives à la conservation des contrats conclus par voie électronique, en l’occurrence dix ans.<br /><br />
            <h2>IV. Hébergement des données personnelles</h2>
            Le site fsc-france.com est hébergé par la société IONOS by 1&1.<br /><br />
            Adresse: 7 Place de la Gare, 57200 Sarreguemines.<br /><br />
            Téléphone: 09 70 80 89 11<br /><br />
            Le stockage des données personnelles des Utilisateurs est exclusivement réalisé sur les centres de données (« clusters ») localisés dans des Etats membres de l’Union Européenne de la société IONOS by 1&1.<br /><br />
            <h2>V. Droit d’accès et de suppression des données personnelles par l’Utilisateur</h2>
            Conformément aux dispositions de la loi 78-17 du 6 janvier 1978 modifiée, l’Utilisateur dispose d’un droit d’accès aux informations collectées par abc digital consulting :<br /><br />
            – l’Utilisateur peut demander à tout moment la modification de ses données<br /><br />
            – l’Utilisateur peut demander à tout moment la rectification de données erronées<br /><br />
            – l’Utilisateur peut demander à tout moment la suppression de données existantes<br /><br />
            Pour exercer ce droit, il reviendra à l’Utilisateur d’envoyer un message à l’adresse suivante : contact@abcdigitalconsulting.com<br /><br />
            <h2>VI. Utilisation de cookies</h2>
            abc digital consulting informe l’Utilisateur qu’elle peut être amenée à utiliser des cookies. Ce dernier accepte expressément l’utilisation des cookies en poursuivant sa navigation sur le site.<br /><br />
            Un cookie est un fichier texte susceptible d’être enregistré dans un terminal lors de la consultation d’un service en ligne avec un logiciel de navigation. Un fichier cookie permet à son émetteur, pendant sa durée de validité, de reconnaître le terminal concerné à chaque fois que ce terminal accède à un contenu numérique comportant des cookies du même émetteur.<br /><br />
            Les cookies émis par abc digital consulting nous permettent :<br /><br />
            d’établir des statistiques et volumes de fréquentation et d’utilisation des diverses éléments composant notre Site, nous permettant d’améliorer la configuration, l’intérêt, le design et l’ergonomie de nos services<br />
            d’adapter la présentation de notre Site aux préférences d’affichage de votre terminal (langue utilisée, résolution d’affichage, système d’exploitation utilisé, etc) lors de vos visites sur notre Site, selon les matériels et les logiciels de visualisation ou de lecture que votre terminal comporte<br />
            de mémoriser des informations relatives à des produits, services ou informations que vous avez choisis sur notre Site (contenu d’un panier de commande, etc.)<br />
            Vous pouvez à tout moment configurer votre logiciel de navigation de manière à ce que des cookies soient enregistrés dans votre terminal ou, au contraire, qu’ils soient rejetés (soit systématiquement, soit selon leur émetteur). Vous pouvez également configurer votre logiciel de navigation de manière à ce que l’acceptation ou le refus des cookies vous soient proposés ponctuellement, avant qu’un cookie puisse être enregistré dans votre terminal.<br /><br />
            Attention : tout paramétrage est susceptible de modifier votre navigation sur Internet et vos conditions d’accès à certains services nécessitant l’utilisation de cookies.<br /><br />
            Pour la gestion des cookies et de vos choix, la configuration de chaque navigateur (Internet Explorer, Firefox, Safari, Google Chrome) est différente. Elle est décrite dans le menu d’aide de votre navigateur, qui vous permettra de savoir de quelle manière modifier vos souhaits en matière de cookies. Vous trouverez ci-dessous des informations concernant les principaux navigateurs.<br /><br />
            Google Analytics<br />
            abc digital consulting utilise les fonctions du service d’analyse web Google Analytics (Google Inc., 1600 Amphitheatre Parkway Mountain View, CA 94043, USA). Google Analytics utilise des cookies. Les informations générées par les cookies relatives à votre utilisation de notre site seront en principe transmises à un serveur de Google aux Etats-Unis et enregistrées sur ce dernier.<br /><br />
            Si l’Utilisateur ne souhaite pas transmettre d’informations à Google Analytics, il a la possibilité de télécharger et d’installer ce module sur son navigateur Web. L’Utilisateur peut télécharger le module de désactivation de Google Analytics en cliquant sur ce lien : https://tools.google.com/dlpage/gaoptout?hl=fr<br /><br />
            Google Ads Conversion-Tracking<br />
            abc digital consulting utilise le programme publicitaire en ligne « Google Ads » et le suivi des conversions. Le suivi des conversions Google est un service d’analyse de Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA).<br /><br />
            Si l’Utilisateur ne souhaite pas participer à ce suivi, il peut refuser cette utilisation en configurant son navigateur. Plus d’informations à ce sujet : https://www.google.com/intl/fr/policies/technologies/ads/ .<br /><br />
            Remarketing<br />
            abc digital consulting utilise la fonction remarketing de Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Au moyen de cette technologie, FSC_FRANCE peut s’adresser aux Utilisateurs du site avec des publicités basées sur leurs centres d’intérêts. Si l’Utilisateur ne souhaite pas recevoir de publicité personnalisée, il peut le configurer : https://www.google.com/settings/u/0/ads/authenticated?hl=fr .<br /><br />
            Mixpanel<br />
            LegalPlace utilise l’outil de suivi de www.mixpanel.com, un service d’analyse web de Mixpanel Inc. (405 Howard Street, 2nd Floor, San Francisco, CA 94105, USA). Mixpanel utilise des cookies. L’Utilisateur peut à tout moment refuser la collecte et l’enregistrement des données en désactivant la fonctionnalité de ce service à l’aide du lien suivant : https://mixpanel.com/optout/. Dans ce cas, un cookie sera toutefois installé sur le terminal de l’Utilisateur afin de ne plus récolter de données de sa part tant que ce dernier ne supprime pas ce cookie.<br /><br />
            Plus d’informations sur la politique de confidentialité de Mixpanel : https://mixpanel.com/terms.<br /><br />
            Facebook<br />
            abc digital consulting utilise le suivi Pixel de Facebook (Facebook Inc., 1601 S. California Ave, Palo Alto, CA 94304, USA) qui permet de suivre le comportement de l’Utilisateur.<br /><br />
            Si l’Utilisateur ne souhaite pas participer à ce suivi, il peut modifier les configurations en cliquant sur ce lien : https://www.facebook.com/ads/website_custom_audiences/ .<br /><br />
            Plus d’informations sur la politique de confidentialité de Facebook : https://www.facebook.com/about/privacy/ .<br /><br />

        </div>

        </Container>        
    </div>
    );
  }
}

export default withStyles(styles)(Politique);