import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button, Stack } from '@mui/material';
import { default as pdp } from '../../../pdp_agent.jpg';
import { default as yes } from '../../../yes.png';
import { default as no } from '../../../no.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import React, { useEffect, useState, useRef } from "react";
import '../index.css';

export default function Step3(props) {
  const [knowDecennie, setKnowDecennie] = useState(false);
  const [decennie, setDecennie] = useState(1);
  const [errorDecennie, setErrorDecennie] = useState(false);
  const [non, setNon] = useState(false);

  const { agent } = props;

  // const messagesEndRef = React.useRef();
  // const anchorr = document.getElementById('buttonAncre');

  // const scrollToBottom = () => {
  //   if (!messagesEndRef.current) return;
  //   messagesEndRef.current.scrollIntoView({ behavior: 'smooth'});
  //   // anchorr.scrollIntoView();
  //   console.log("Scroll");
  // }

  // useEffect(() => {
  //   scrollToBottom()
  // }, [knowDecennie]);

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  const handleSubmit = () => {
    if ((decennie == 1 && !knowDecennie) || (decennie != 1 && decennie != null && 1== 1)){
      props.addToData({constructionYear: decennie});
      props.nextStep(5);
    } else {
      setErrorDecennie(true);
    }
  }

  const displayDecennieChoice = () => {
    if(1 == 1 ){
      return (
        <>
        <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true'>

          <Card className='cardChat'>
                <Row>
                    <Col xs={12} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={12} className='colCardChatBody'>
                        <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>Indiquez la décennie de votre bien:</Card.Body>
                    </Col>
                </Row>
          </Card>
        </div>

        <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor="buttonAncre">
        <Row style={{marginTop: '20px'}}>
          <Col>
            <Form.Select onChange={(e) => setDecennie(Number(e.target.value))} className='selectDecennie'>
              <option value={null}>Choisir une décennie</option>
              <option value={null}>Je ne sais pas</option>
              <option value={2}>Avant 1850</option>
              <option value={3}>De 1850 à 1913</option>
              <option value={4}>De 1914 à 1947</option>
              <option value={5}>De 1948 à 1969</option>
              <option value={6}>De 1970 à 1980</option>
              <option value={6}>De 1981 à 1991</option>
              <option value={6}>De 1992 à 2000</option>
              <option value={6}>De 2001 à 2010</option>
              <option value={7}>Après 2011</option>
            </Form.Select>
          </Col>
        </Row>
        {errorDecennie ?
          <Row style={{marginTop: '5px'}}>
              <p className='errorDecennie'>Veuillez sélectionner une décennie</p>
          </Row>
          : null}
        <Row className='rowNextButton'>
            <Button
            type="submit"
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            className='nextButton'
            onClick={handleSubmit}
            >
              Suivant
            </Button>
        </Row>
        </div>
        {/* <div ref={messagesEndRef} /> */}
        </>

      )
    }
  }

  return(
    <Container fluid className='containerEstimation' style={props.style()}>
      {displayDecennieChoice()}
    </Container>
  )
}
