import React from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";
import GooglePlacesAutoComplete from 'react-google-places-autocomplete';
import { Container, Typography, Button, Icon, TextField , Card, Grid} from "@mui/material";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Autocomplete from '@mui/material/Autocomplete';
import { PropTypes } from "prop-types";
import { withStyles } from '@mui/styles';
import { Link, Navigate } from 'react-router-dom';
import Header from './Header/Header';
import GoogleMaps from './Input/Input'
 import './welcome.css'
import { default as wel } from '../../welcome.png';


import '../StepperPage/index.css';




import logo from '../../logo_cg_1.svg'
var axios = require('axios');







const styles = {
  area : {
    //background: "linear-gradient(to  bottom, white, rgba(250, 250, 250, 0.9), rgba(255, 206, 0, 0.3))",
    // background: "-webkit-linear-gradient(to left, #8f94fb, #4e54c8)",
    backgroundColor: 'white',
    width: '100vw',
    minHeight: "100vh",
    display: "flex",
    justifyContent: 'center',
    alignItems: 'center',
    margin: 0,
    padding: '20px'
},

input : {
  padding: '15px',
  height: "6vh",
  marginTop: "5vh",
  fontSize: "19px",
  justifyContent: 'left',
  /* font-style: italic; */
  "@media (max-width: 900px)": {
    width: "82vw",
  },
  width: "40vw",
   border: 'none',
   outline: 'none',
  borderRadius: '5rem'
},
  circles : {
    position: 'absolute',
    top: 0,

    left: 0,
    width: '100vw',
    height: "100vh",
    overflow: "hidden",
},
  buttonn: {
    borderRadius: '20px',
    backgroundColor: '#00A0EB',
    textDecoration: 'none',
    borderColor: '#00A0EB',
    fontWeight: 700,
    boxShadow: '4 5 17px 5 rgb(0 0 0 / 46%)'
  },

    cardChatImg: {
    height: '54px',
    width: '54px',
    borderRadius: '100%',
    objectFit: 'cover',
    border: '3px solid #FFCE00',
  },
  cardChat: {
    border: 'none',
    backgroundColor: '#ffffff',
  },
  cardChatBody: {
    display: 'inline-block',
    fontFamily: 'SourceSansPro, sans-serif',
    borderRadius: '0px 20px 20px 20px',
    backgroundColor: 'rgba(255, 206, 0, 0.6)',
    color: '#515151',
    fontWeight: 400,
    lineHeight: 1.5,
    fontSize: '1.125rem',
  },
   colCardChatBody: {
    alignSelf: 'center',
  },


};

class WelcomePage extends React.Component{



  constructor(props) {
    super(props);
    this.state = {
      address: '',
      lat: null,
      lon: null,
      agent: null,
      agent_name: null,
      redirect: false,
      link: '#',
      clicked: false,
    };
  }



  componentDidMount(){
    console.log(this.props);
    const agentName = this.props.location.pathname.split('/')[1];
    const data = JSON.stringify({"agent": agentName});

    //let urlProd = 'https://api.esti-immo.fr/agent/getOne';

    let urlProd = 'https://api.estimateur-immo.com/agent/getOne';

    let urlDev = "http://localhost:3004/agent/getOne";
    const config = {
      method: 'post',
      url: urlProd,
      headers: {
        'Content-Type': 'application/json'
      },
      data : data
    };
    axios(config)
    .then((response) => {
      console.log(JSON.stringify(response.data));
      if (response.data == ""){
        console.log("C'est guillemets");
        this.setState({redirect: true});
      } else {
        this.setState({
          agent: response.data,
          agent_name: agentName
        })
      }

    })
    .catch((error) => {
      this.setState({redirect: true});
      console.log(error);
    });


    const script = document.createElement("script");

    script.innerHTML = `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '242491718805755');
        fbq('track', 'PageView');
    `;

    document.body.appendChild(script);

  }

  redirecting = () => {
    console.log("GO 404");
    if (this.state.redirect){
      return(
        <Navigate to='/404' />
      )
    }
  }

  getLink = () => {
    const { lat, lon, address, agent_name } = this.state;
    if (lat != null && lon != null && address != ''){
      this.toggleZoom();
      const link = '/estimation/' + agent_name;
      return link;
    }
    return '#';
  }

  handleChange = () => {
    console.log("IN HANDLE CHANGE");
    // this.setState({ address });
    // console.log(this.state.address);
    if (this.state.clicked){
      this.setState({lon: null, lat: null});
    }
  };

  handleSelect = address => {
    console.log("IN HANDLE SELECT");
    this.setState({ address: address.description });
    console.log(address);
    geocodeByAddress(address.description)
    .then(results => getLatLng(results[0]))
    .then(latLng => {
      console.log('Success', latLng);
      this.setState({lat: latLng.lat, lon: latLng.lng, clicked: true});
      console.log(this.state);
    })
    .catch(error => console.error('Error', error));
    // getLatLng(address)
    //   .then((res) => console.log(res))
    //   .catch((err) => console.log(err));
  }

  toggleZoom = () => {
    console.log("IN TOGGLE ZOOM");
    document.body.style.zoom = '100%';
  }


  render() {
    const { classes } = this.props;


    const { address, lon, lat, agent_name, link } = this.state;



    return (
      <div className={classes.area} style={{ flexDirection: 'row' }}>
        {this.redirecting()}
        <div style={{ flex: '2', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <div className='circles'>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
                      <li></li>
          </div>
          <Header agent={agent_name} logo={this.state.agent != null ? this.state.agent.logo : null}/>
              {/* <div
              maxWidth="sm"
               style={{
              position: 'absolute',
              left: '50%',
              top: '50%',
              justifyContent: 'center',
              flex:
              alignContent: 'center',
              transform: 'translate(-50%, -50%)'
          }}> */}
          <div
            style={{
              // color: 'r'
               width: '90%',
              // position: 'absolute',
              // left: '50%',
              // top: '50%',
               justifyContent: 'left',
               alignContent: 'center',
              // transform: 'translate(-50%, -50%)'
          }}>
            

           {/* <Typography color='grey' fontWeight='600' textAlign="left" variant="h4" gutterBottom id='titleWelc'>Estimez la valeur de votre bien immobilier</Typography>*/}



          <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once="true">
            {/* Contenu de l'élément */}
            <div style={{ marginTop: '0px', marginLeft: '1%' , width: '100%' }}>
              <Card className={classes.cardChat} style={{ boxShadow: '0 0 0px 0 rgb(0 0 0 / 0%)' }}>
                <Grid container>
                  <Grid item xs={12} className={classes.colCardChatImg}>
                    <Grid container>
                      <Grid item xs={2}>
                        <img className={classes.cardChatImg} src={this.state.agent != null ? this.state.agent.pdp : null} alt="pdp_agent" style={{ marginBottom: '10px' , width: '60px' , height: '60px'}}/>
                      </Grid>
                      <Grid item xs={10} style={{ marginTop: '-55px', marginLeft: '100px' }}>
                        <Typography>
                             {this.state.agent ? `${this.state.agent.name} ${this.state.agent.lastname}` : "Agent"}
                          </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                      <Typography style={{ padding: '20px 10px 10px 10px' , fontWeight: '650' , fontSize: '1.6rem'}}>
                        Estimez la valeur de votre bien immobilier.
                      </Typography>
                  </Grid>
                  <Grid item xs={12} className={classes.colCardChatBody} style={{ }}>
                      <Typography style={{ padding: '10px'}}>
                        Bonjour !<br /> Je m'appelle   {this.state.agent ? `${this.state.agent.name} ${this.state.agent.lastname}` : "Agent"}. Je suis agent immobilier et spécialiste de votre secteur chez {this.state.agent ? this.state.agent.agence_name : "notre agence"}.
                        <br />Commençons l'estimation de votre bien.
                      </Typography>
                      <Typography style={{ padding: '10px' , fontSize: '1.6rem' , fontWeight: '650'}}>
                       Entrez votre adresse:
                      </Typography>
                  </Grid>
                </Grid>
              </Card>
            </div>
          </div>






            <div className='containerCard' style={{boxShadow: '0 0 9px 0 rgb(0 0 0 / 17%)', padding: '15px 9px 6px', display: 'flex', justifyContent: 'center', marginTop: '20px', borderRadius: '10px', width: '100%', marginLeft: '0%'}}>
              <GoogleMaps onSelect={this.handleSelect} onChange={this.handleChange}></GoogleMaps>
            </div>

            <div id='btnGo'
              style={{
                display:'flex',
                marginLeft: '32%',
                alignContent: 'center',
              }}
            >
              <Button type="submit"  variant="contained" endIcon={<ArrowForwardIcon />} style={{borderRadius: '10px', backgroundColor: '#00A0EB', textDecoration: 'none', borderColor: '#00A0EB', fontWeight: 700, boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', marginTop: '5vh', padding: '20px', width: '50%'}}>
                <Link style={{color: 'white', textDecoration: 'none'}} to={this.getLink()} state={{address: address, lon: lon, lat: lat}}>Lancer mon estimation</Link>
              </Button>
            </div>
          </div>
        </div>


        <div style={{ flex: '1' }} className='divImgWelcomePage'>
          <img src='https://images.pexels.com/photos/1080696/pexels-photo-1080696.jpeg?auto=compress&cs=tinysrgb&w=800' style={{ width: '105%', height: 'auto', marginTop: '84px', borderLeft: '5px solid #FFCE00' }} />
        </div>
      </div>

    );
  }
}

export default withStyles(styles)(WelcomePage);
