import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { default as pdp } from '../../../pdp_agent.jpg';
import '../../StepperPage/index.css';

export default function Step3(props) {
  return (
    <Container fluid className='containerEstimation'>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <Card className='cardChat' style={{marginBottom: '15px'}}>
                <Row>
                    <Col xs={2} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={props.agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={10} className='colCardChatBody'>
                        <Card.Body className="cardChatBody">
                            C'est noté ! Je vous appellerai dans les 24 heures pour que nous puissions discuter de votre projet.
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
      </div>
    </Container>
  )
}