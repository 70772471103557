import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button } from '@mui/material';
import { default as pdp } from '../../../../../pdp_agent.jpg';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../../../index.css';
var axios = require('axios');


export default function Step11MailB(props) {
  const { agent } = props;

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
      
        <Card className='cardChat'>
            <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='result1'>
            <Row>
                <Col xs={2} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>
                    Vous allez recevoir, rapidement, un mail avec le résultat de votre estimation.
                    </Card.Body>
                </Col>
            </Row>
            </div>

            <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='result2'>
            <Row style={{marginTop: '10px'}}>
                <Col xs={2} className='colCardChatImg'></Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody">
                    Merci et à tout de suite.
                    </Card.Body>
                </Col>
            </Row>
            </div>
        </Card>
    </Container>
  )
}