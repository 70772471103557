import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { default as pdp } from '../../../../../pdp_agent.jpg';

export default function Step2T2NonProprio(props) {
  const agent = props.agent;

  const displayAgenceName = () => {
    return props.agent.agence_name.replaceAll('_', ' ');
  }

  return (
    <Container fluid className="containerEstimation">
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <Card className='cardChat' style={{marginBottom: '15px'}}>
                <Row>
                    <Col xs={2} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={props.agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={10} className='colCardChatBody'>
                        <Card.Body className="cardChatBody">
                        Je peux me déplacer et faire un point complet de votre situation.
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
      </div>
      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
            <Card className='cardChat'>
                <Row>
                    <Col xs={2} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={props.agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={10} className='colCardChatBody'>
                        <Card.Body className="cardChatBody">
                        Voici mon TEL direct<br />
                        {agent.tel}<br />
                        {agent.email}<br />
                        {agent.lastname} {agent.name}<br />
                        Agence {displayAgenceName()}<br />
                        {agent.adresse}<br />
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
      </div>
    </Container>
  )
}