import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button, Stack } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import { default as pdp } from '../../../../pdp_agent.jpg';
import { default as diamant } from '../../../../diamant.png';
import { default as outils } from '../../../../outils.png';
import { default as stand } from '../../../../standard.png';
import { default as pioch } from '../../../../pioche.png';
import { default as yes } from '../../../../yes.png';
import { default as no } from '../../../../no.png';
import '../../index.css';

export default function Step4a(props){
  const [tn, setTn] = useState(false);
  const [ouiBan, setOuiBan] = useState(false);
  const [nonBan, setNonBan] = useState(false);
  const [ouiTn, setOuiTn] = useState(false);
  const [nonTn, setNonTn] = useState(false);

  const { agent } = props;

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  }




  const [refais, setRefais] = useState(false);


    const onSubmitRefais = async() => {
      if (!refais){
        setRefais(true);
        props.addToData({standing: 4});
        await sleep(1000);
        props.nextStep(6);
      }
    }


  const [standard, setStandard] = useState(false);


    const onSubmitStandard = async() => {
      if (!standard){
        setStandard(true);
        props.addToData({standing: 3});
        await sleep(1000);
        props.nextStep(6);
      }
    }

    const [petitTravaux, setPetitTravaux] = useState(false);

    const onSubmitPetitTravaux = async() => {
      if (!petitTravaux){
        setPetitTravaux(true);
        props.addToData({standing: 2});
        await sleep(1000);
        props.nextStep(6);
      }
    }

    const [grosTravaux, setGrosTravaux] = useState(false);

    const onSubmitGrosTravaux= async() => {
      if (!grosTravaux){
        setGrosTravaux(true);
        props.addToData({standing: 1});
        await sleep(1000);
        props.nextStep(6);
      }
    }

  const displayTravauxNecessaires = () => {
    if(nonBan){
      return (
        <>
        <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='travaux1'>
          <Card className='cardChat'>
              <Row>
                  <Col xs={12} className='colCardChatImg'>
                      <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                  </Col>
                  <Col xs={12} className='colCardChatBody'>
                      <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>Des travaux seront nécessaires ?</Card.Body>
                  </Col>
              </Row>
          </Card>
        </div>

        <div data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='travaux2'>
          <Stack direction="row" justifyContent='center' style={{marginTop: '20px'}}>
            {/* maxWidth: 992px */}
              <div
                className='containerCard'
                onClick={() => {
                    setOuiTn(true);
                    setNonTn(false);
                    // await sleep(2000);
                    props.nextStep(5);
                  }
                }
              >
                <label className='labelCard'>
                  <input type='radio' className='inputCard' />
                  <div className='container2Card'>
                    <span className='okCard'>
                      {ouiTn ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={yes} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Oui</span>
                    </p>
                  </div>
                </label>
              </div>
            {/* maxWidth: 992px */}
              <div
                className='containerCard'
                onClick={() => {
                    setNonTn(true);
                    setOuiTn(false);
                    props.addToData({standing: 4});
                    // await sleep(2000);
                    props.nextStep(7);
                  }
                }
              >
                <label className='labelCard'>
                  <input type='radio' className='inputCard' />
                  <div className='container2Card'>
                    <span className='okCard'>
                      {nonTn ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={no} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Non</span>
                    </p>
                  </div>
                </label>
              </div>
          </Stack>
        </div>
        </>
      )
    }
  }

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true'>
          <Card className='cardChat'>
              <Row>
                  <Col xs={12} className='colCardChatImg'>
                      <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                  </Col>
                  <Col xs={12} className='colCardChatBody'>
                      <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>Quel est l'etat du bien ?</Card.Body>
                  </Col>
              </Row>
          </Card>
      </div>

      <div data-aos="fade-up" data-aos-delay="600" data-aos-duration="1000" data-aos-once='true'>
        <Stack direction="row" justifyContent='center' style={{marginTop: '20px'}}>
          {/* maxWidth: 992px */}
            <div
              className='containerCard'
              onClick={onSubmitRefais}
            >
              <label className='labelCard'>
                <input type='radio' className='inputCard' />
                <div className='container2Card'>
                  <span className='okCard'>
                    {refais ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                  </span>
                  <div className='containerImgCard'>
                    <img className='imgCard' src={diamant} />
                  </div>
                  <p className='titleCard'>
                    <span className='spanTitleCard'>Refais a neuf</span>
                  </p>
                </div>
              </label>
            </div>
            <div
              className='containerCard'
              onClick={onSubmitStandard}
            >
              <label className='labelCard'>
                <input type='radio' className='inputCard' />
                <div className='container2Card'>
                  <span className='okCard'>
                    {standard ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                  </span>
                  <div className='containerImgCard'>
                    <img className='imgCard' src={stand} />
                  </div>
                  <p className='titleCard'>
                    <span className='spanTitleCard'>Standard</span>
                  </p>
                </div>
              </label>
            </div>
        </Stack>
        <Stack direction="row" justifyContent='center' style={{marginTop: '20px'}}>
          {/* maxWidth: 992px */}
            <div
              className='containerCard'
              onClick={onSubmitPetitTravaux}
            >
              <label className='labelCard'>
                <input type='radio' className='inputCard' />
                <div className='container2Card'>
                  <span className='okCard'>
                    {petitTravaux ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                  </span>
                  <div className='containerImgCard'>
                    <img className='imgCard' src={outils} />
                  </div>
                  <p className='titleCard'>
                    <span className='spanTitleCard'>Petit travaux</span>
                  </p>
                </div>
              </label>
            </div>
            <div
              className='containerCard'
              onClick={onSubmitGrosTravaux}
            >
              <label className='labelCard'>
                <input type='radio' className='inputCard' />
                <div className='container2Card'>
                  <span className='okCard'>
                    {grosTravaux ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                  </span>
                  <div className='containerImgCard'>
                    <img className='imgCard' src={pioch} />
                  </div>
                  <p className='titleCard'>
                    <span className='spanTitleCard'>Gros travaux</span>
                  </p>
                </div>
              </label>
            </div>
        </Stack>
      </div>
    </Container>
  )
}
