import { Button, IconButton, Typography } from "@mui/material";
import { Container, Col, Row, Card, FormGroup, Form, FormLabel } from "react-bootstrap";
import { default as pdp } from '../../../../pdp_agent.jpg';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box } from "@mui/system";
import { useState } from "react";
import '../../index.css';

export default function Step2b(props) {
    const [validated, setValidated] = useState(false);
    const [surface, setSurface] = useState(null);
    const [chambres, setChambres] = useState(null);
    const [pieces, setPieces] = useState(null);
    const [sdb, setSdb] = useState(null);
    const [eb, setEb] = useState(null);
    const [eti, setEti] = useState(null);
    const [error, setError] = useState(null);

    const { agent } = props;



    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {


            var isGoodSelect = true
            if ((pieces === null )){

                isGoodSelect = false
                console.log('La valeur de pieces:', pieces);
                event.preventDefault();
                setError("vous devez selectionner un nombre de pièces 1 ");

            } else if ((chambres === null)){

                isGoodSelect = false
                event.preventDefault();
                setError("vous devez selectionner un nombre de chambres");

            }


            if (isGoodSelect === true) {


                console.log('La valeur de pieces:', pieces);
                console.log('La valeur de chambres :', chambres);

                if (pieces >= chambres ){
                    const etageBien = props.lieu == 'maison' ? 0 : eb;
                    const etageTotal = props.lieu == 'maison' ? 0 : eti;
                    props.addToData({ roomNb: pieces, bedroomNb: chambres});
                    console.log("ON ENVOIE NEXT STEP 4")
                    props.nextStep(4);
                }  else {
                    event.preventDefault();
                    setError("Le nombre de chambres ne peut pas être supérieur au nombre de pièces.");
                }

            }

        }

        setValidated(true);

    }

    const addToValue = (label) => {
        switch (label) {
            case 'surface':
                setSurface(surface + 1);
                break;
            case 'chambres':

                if (chambres == "4+") {
                    chambres = 4
                }
                setChambres(chambres + 1);
                break;
            case 'pieces':

                if (pieces == "5+") {
                    pieces = 5
                }
                setPieces(pieces + 1);
                break;
            case 'sdb':
                setSdb(sdb + 1);
                break;
            case 'eb':
                setEb(eb + 1);
                break;
            case 'eti':
                setEti(eti + 1);
                break;
            default:
                break;
        }
    }

    const removeToValue = (label) => {
        switch (label) {
            case 'surface':
                if (surface > 0 && !isNaN(surface))
                  setSurface(surface - 1);
                break;
            case 'chambres':
                if (chambres > 0 && !isNaN(chambres))
                  setChambres(chambres - 1);
                break;
            case 'pieces':
                if (pieces > 0 && !isNaN(pieces))
                  setPieces(pieces - 1);
                break;
            case 'sdb':
                if (sdb > 0 && !isNaN(sdb))
                  setSdb(sdb - 1);
                break;
            case 'eb':
                if (eb > 0 && !isNaN(eb))
                  setEb(eb - 1);
                break;
            case 'eti':
                if (eti > 0 && !isNaN(eti))
                  setEti(eti - 1);
                break;
            default:
                break;
        }
    }

    return (
        console.log(props.lieu),
        <Container fluid className='containerEstimation' style={props.style()}>
          <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
            <Card className='cardChat'>
                <Row>
                    <Col xs={12} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={12} className='colCardChatBody'>
                        <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>Comment se compose votre bien ?</Card.Body>
                    </Col>
                </Row>
            </Card>
          </div>

          <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000">
          <Form noValidate validated={validated} onSubmit={handleSubmit}>

            <Row>
                <Col md={12} lg={12}>
                  <Row style={{ marginTop: '20px' }}>
                    <Col md={4} xs={8}>
                      <div className='labelNb'>
                        <FormLabel>Nbre de pièces</FormLabel>
                      </div>
                    </Col>
                    <Col md={8} xs={12}>
                      <div className='containerCases'>
                        {[1, 2, 3, 4, '5+'].map((value) => (
                          <div
                            key={value}
                            className={`case ${pieces === value || (value === '5+' && pieces === 5) ? 'active' : ''}`}
                            onClick={() =>
                                {
                                    if (value == "5+") {
                                        setPieces(5)
                                    } else {
                                        setPieces(value)
                                    }
                                }
                            }
                            required
                          >
                            {value}
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Col>
            </Row>

            <Row>
                <Col md={12} lg={12}>
                  <Row style={{ marginTop: '20px' }}>
                    <Col md={4} xs={8}>
                      <div className='labelNb'>
                        <FormLabel>Nbre de chambres</FormLabel>
                      </div>
                    </Col>
                    <Col md={8} xs={12}>
                      <div className='containerCases'>
                        {[0, 1, 2, 3, '4+'].map((value) => (
                          <div
                            key={value}
                            className={`case ${chambres === value || (value === '4+' && chambres === 4) ? 'active' : ''}`}

                            onClick={() =>
                                {
                                    if (value == "4+") {
                                        setChambres(4)
                                    } else {
                                        setChambres(value)
                                    }
                                }
                            }
                            required
                          >
                            {value}
                          </div>
                        ))}
                      </div>
                    </Col>
                  </Row>
                </Col>
            </Row>


            <Row style={{marginTop: '15px'}}>
                <Typography style={{color: 'red'}}>{error}</Typography>
            </Row>

            <Row className='rowNextButton'>
              <Button
                type="submit"
                variant="contained"
                endIcon={<ArrowForwardIcon />}
                className='nextButton'
              >
                Suivant
              </Button>
            </Row>
          </Form>
          </div>
        </Container>
    )
}
