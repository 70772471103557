import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { default as pdp } from '../../../pdp_agent.jpg';
import { default as terrass } from '../../../terrasse.png';
import { default as cav } from '../../../cave.png';
import { default as terrai } from '../../../terrain.png';
import { default as piscin } from '../../../piscine.png';
import { default as va } from '../../../vis-a-vis.png';
import { default as fenet } from '../../../fenetre-sv.png';
import { default as sta } from '../../../star.png';
import { default as sta2 } from '../../../stars2.png';
import { default as sta3 } from '../../../stars3.png';
import { default as lands } from '../../../landscape.png';
import { default as eiff } from '../../../eiffel.png';
import '../index.css';

import AccordionBody from "react-bootstrap/esm/AccordionBody";

export default function Step6(props) {
  const [terrasse, setTerrasse] = useState(false);
  const [sTerrasse, setSTerrasse] = useState(null);
  const [cave, setCave] = useState(false);
  const [sCave, setSCave] = useState(null);
  const [terrain, setTerrain] = useState(false);
  const [sTerrain, setSTerrain] = useState(null);
  const [jardin, setJardin] = useState(false);
  const [sJardin, setSJardin] = useState(null);
  const [piscine, setPiscine] = useState(false);
  const [validated, setValidated] = useState(false);



  const [sansVisAVis, setSansVisAVie] = useState(false);
  const [belleVue, setBelleVue] = useState(false);
  const [exceptionnelleVue, setExceptionnelleVue] = useState(false);



  const { agent } = props;

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
    } else {
        const nbTerrace = terrasse ? 1 : 0;
        const cellar = cave ? 1 : 0;
        const swimmingPool = piscine ? 1 : 0;
        // const gardenSurface = (props.lieu == 'maison' && terrain) ? sTerrain : 0;

        const gardenSurface = (props.lieu == 'maison' && terrain) ? 100 : 0;

        props.addToData({terraceNb: nbTerrace, cellar: cellar, swimmingPool: swimmingPool, gardenSurface: gardenSurface, surfCaveGrenier: cave ? sCave : 0, surfTerrasseBalcon: terrasse ? sTerrasse : 0});

        if (exceptionnelleVue == true) {
         props.addToData({view: 3});
        }
        if (belleVue == true) {
         props.addToData({view: 3});
        }

        if (sansVisAVis == true) {
         props.addToData({view: 2});
        }




        if (props.lieu == 'appart'){
          props.addToData({surfaceJardin: jardin ? sJardin : 0});
        }
        props.nextStep(10)
    }
    setValidated(true);

  }

  const addToValue = (label) => {
    switch (label) {
        case 'terrasse':
            setSTerrasse(sTerrasse + 1);
            break;
        case 'cave':
            setSCave(sCave + 1);
            break;
        case 'jardin':
            setSJardin(sJardin + 1);
            break;
        case 'terrain':
            setSTerrain(sTerrain + 1);
            break;
        default:
            break;
    }
  }

  const removeToValue = (label) => {
      switch (label) {
          case 'terrasse':
              if (sTerrasse > 0 && !isNaN(sTerrasse))
                setSTerrasse(sTerrasse - 1);
              break;
          case 'cave':
              if (sCave > 0 && !isNaN(sCave))
                setSCave(sCave - 1);
              break;
          case 'jardin':
              if (sJardin > 0 && !isNaN(sJardin))
                setSJardin(sJardin - 1);
              break;
          case 'terrain':
              if (sTerrain > 0 && !isNaN(sTerrain))
                setSTerrain(sTerrain - 1);
              break;
          default:
              break;
      }
  }

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='annexe1'>
        <Card className='cardChat'>
          <Row>
            <Col xs={12} className='colCardChatImg'>
              <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
            </Col>
            <Col xs={12} className='colCardChatBody'>
              <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>Votre bien bénéficie-t-il d'un de ces avantages?</Card.Body>
            </Col>
          </Row>
        </Card>
      </div>


      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='annexe2'>
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row style={{marginTop: '20px'}}>

            <Col xs={4} sm={4} lg={3} xl={3} className='colGroupCardRightt'>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setTerrasse(!terrasse)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {terrasse ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={terrass} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Terrasse</span>
                    </p>
                  </div>
                </label>
              </div>
            </Col>

            <Col xs={4} sm={4} lg={3} xl={3} className='colGroupCardLeftt'>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setCave(!cave)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {cave ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={cav} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>{props.lieu == 'appart' ? 'Cave' : 'Grenier'}</span>
                    </p>
                  </div>
                </label>
              </div>
            </Col>

            {props.lieu == 'maison' ?
            <Col xs={4} sm={4} lg={3} xl={3} className='colGroupCardRightt'>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setTerrain(!terrain)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {terrain ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={terrai} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Terrain</span>
                    </p>
                  </div>
                </label>
              </div>
            </Col>
            :
            <Col xs={4} sm={4} lg={3} xl={3} className='colGroupCardRightt'>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setJardin(!jardin)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {jardin ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={terrai} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Jardin</span>
                    </p>
                  </div>
                </label>
              </div>
            </Col>}

            <Col xs={4} sm={4} lg={3} xl={3} className='colGroupCardLeftt'>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setPiscine(!piscine)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {piscine ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={piscin} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Piscine</span>
                    </p>
                  </div>
                </label>
              </div>
            </Col>


            <Col xs={4} sm={4} lg={3} xl={3} className='colGroupCardRightt'>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setSansVisAVie(!sansVisAVis)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {sansVisAVis ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={fenet} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Sans vis-à-vis</span>
                    </p>
                  </div>
                </label>
              </div>
            </Col>

            <Col xs={4} sm={4} lg={3} xl={3} className='colGroupCardLeftt'>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setBelleVue(!belleVue)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {belleVue ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={lands} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Belle vue</span>
                    </p>
                  </div>
                </label>
              </div>
            </Col>

            <Col xs={4} sm={4} lg={3} xl={3} className='colGroupCardLeftt'>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setExceptionnelleVue(!exceptionnelleVue)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {exceptionnelleVue ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={eiff} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Super vue</span>
                    </p>
                  </div>
                </label>
              </div>
            </Col>

          </Row>
          <div data-aos="fade-up" data-aos-delay="800" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='annexe3'>
          <Row className='rowNextButton'>
            <Button
            type="submit"
            // onClick={() => props.nextStep(9)}
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            style={{marginTop: '30px', textTransform: 'none', borderRadius: '20px', backgroundColor: '#00A0EB', borderColor: '#00A0EB', fontSize: '1.5rem', fontWeight: 700, boxShadow: '0 0 17px 0 rgb(0 0 0 / 26%)', marginTop: '1vh', maxWidth: '50%', padding: '11px 0'}}
            >
            Suivant
            </Button>
          </Row>
          </div>
        </Form>
      </div>



    </Container>
  )
}
