import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button, Stack } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { default as pdp } from '../../../pdp_agent.jpg';
import '../index.css'

export default function Step8(props) {
  const [displayMsg, setDisplayMsg] = useState(false);

  const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
  } 

  const onClickButton = async(e) => {
    if (!displayMsg){
        props.addToData({prisEnCompteEssentiel: e.target.name});
        setDisplayMsg(true);
        await sleep(2000);
        props.nextStep(10);
    }
  }

  const { agent } = props;

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
        <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='essen1'>
            <Card className='cardChat'>
                <Row>
                    <Col xs={12} className='colCardChatImg'>
                        <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                    </Col>
                    <Col xs={12} className='colCardChatBody'>
                        <Card.Body className="cardChatBody">
                            Merci pour ces premiers éléments. D'après-vous, a-t-on pris en compte l'essentiel ?
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </div>

        <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='essen2'>
            <Row>
                <Stack spacing={2} direction="column" alignItems='center'>
                    <Button variant="outlined" name='Plutôt oui' onClick={onClickButton} className='interestedButton'>Plutôt oui</Button>
                    <Button variant="outlined" name='Je ne suis pas sûr' onClick={onClickButton} className='interestedButton'>Je ne suis pas sûr</Button>
                    <Button variant="outlined" name='Plutôt non' onClick={onClickButton} className='interestedButton'>Plutôt non</Button>
                </Stack>
            </Row>
        </div>

        {displayMsg ?
        <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='essen3'>
        <Card className='cardChat' style={{marginTop: '20px'}}>
            <Row>
                <Col xs={2} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={10} className='colCardChatBody'>
                    <Card.Body className="cardChatBody">
                        Ok. J'ai bien noté.
                    </Card.Body>
                </Col>
            </Row>
        </Card>
        </div> : null}

    </Container>
  )
}