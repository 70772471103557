import { useState } from "react";
import { Container, Row, Col, Card, Form } from "react-bootstrap";
import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { default as pdp } from '../../../pdp_agent.jpg';
import { default as mais } from '../../../maison2.png';
import { default as park } from '../../../park.png';
import { default as brui } from '../../../bruit.png';
import { default as ascenseu } from '../../../ascenseur.png';
import '../index.css';

export default function Step5(props) {
  const [maisonMit, setMaisonMit] = useState(false);
  const [soleil, setSoleil] = useState(false);
  const [bruit, setBruit] = useState(false);
  const [ascenseur, setAscenseur] = useState(false);

    const [parking, setParking] = useState(false);


  const { agent } = props;

  const onSubmit = () => {
    props.addToData({maisonMitoyenne: maisonMit, doubleOrientation: soleil, bruitImportant: bruit});
    // if (props.lieu == 'appart'){
    props.addToData({elevator: ascenseur ? 1 : 0});
    // }
    props.nextStep(7);
  }

  return (
    <Container fluid className='containerEstimation' style={props.style()}>
      <div data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='carac1'>
        <Card className='cardChat'>
            <Row>
                <Col xs={12} className='colCardChatImg'>
                    <Card.Img className='cardChatImg' src={agent.pdp} width="54px" height="54px" alt="pdp_agent"/>
                </Col>
                <Col xs={12} className='colCardChatBody'>
                    <Card.Body className="cardChatBody" style={{ fontSize: '1.6rem' , fontWeight: '650'}}>
                      Votre bien possède-t-il l'une de ces caractéristiques?
                    </Card.Body>
                </Col>
            </Row>
        </Card>
      </div>


      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='carac2'>
          <Row style={{marginTop: '20px'}}>

            {props.lieu == 'maison' ?
            <Col xs={12} lg={6}>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setMaisonMit(!maisonMit)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {maisonMit ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={mais} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Maison mitoyenne</span>
                    </p>
                  </div>
                </label>
              </div>
            </Col>
              :
            <Col xs={12} lg={6}>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setAscenseur(!ascenseur)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {ascenseur ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={ascenseu} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Ascenseur</span>
                    </p>
                  </div>
                </label>
              </div>
            </Col>}

            <Col xs={12} lg={6}>
              <div className='containerCard'>
                <label className='labelCard'>
                  <input type='radio' className='inputCard' onClick={() => setParking(!parking)}/>
                  <div className='container2Card'>
                    <span className='okCard'>
                      {parking ? <CheckIcon fontSize='medium' className='colorOkCard' /> : null}
                    </span>
                    <div className='containerImgCard'>
                      <img className='imgCard' src={park} />
                    </div>
                    <p className='titleCard'>
                      <span className='spanTitleCard'>Parking</span>
                    </p>
                  </div>
                </label>
              </div>
              </Col>
          </Row>
      </div>

      <div data-aos="fade-up" data-aos-delay="400" data-aos-duration="1000" data-aos-once='true' data-aos-anchor='carac3'>
        <Row className='rowNextButton'>
          <Button
            type="submit"
            onClick={onSubmit}
            variant="contained"
            endIcon={<ArrowForwardIcon />}
            className='nextButton'
          >
              Suivant
          </Button>
        </Row>
      </div>
    </Container>
  )
}
